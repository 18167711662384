import React from "react";
import { Container } from "react-bootstrap";
import ApplicationLink from "../landing/components/ApplicationLink";
import ContactusForm from "./components/ContactusForm";
import ContactUsModal from "views/components/modals/ContactUsModal";
import DefaultButton from "views/components/button/DefaultButton";
import LandingPageFooter from "views/components/footer/LandingPageFooter";
import ENV from "utils/helpers/env";

const ContactUs = () => {
  const [show, setShow] = React.useState(false);

  return (
    <>
      <div className="introduce-bg-color">
        <Container>
          <div className="contactus row">
            <div className="col-md-6 contact-info">
              <div className="head-title">
                <div className="contact-header mb-3 thick-font">Contact Us</div>
                <div className="description thick-font dark-gray-text">
                  We&apos;re happy to answer and get you acquainted with&nbsp;
                  {ENV.WL_BUSINESS_NAME}.
                </div>
                <img
                  className="under-line"
                  src="/assets/images/landing/yellow-divider.png"
                  alt="Yellow-divider"
                />
              </div>

              <div className="middle">
                <div className="mb-3 thick-font dark-gray-text">
                  Fill out the form to get in touch with one of our
                  representatives.
                </div>
                {!ENV.WL_CONTACT_EMAIL && !ENV.WL_CONTACT_WA ? null : (
                  <>
                    <div className="mb-3">OR</div>
                    <div className="mb-3 know-more">
                      <DefaultButton
                        onClick={() => setShow(true)}
                        title="Talk to Us"
                        className="w-fit px-4 font-bold"
                      />
                    </div>
                  </>
                )}
              </div>

              {ENV.WL_HIDE_HELPDESK ? null : (
                <div className="end">
                  <div className="FAQ-pc mb-3 thick-font dark-gray-text">
                    For technical issues or general queries, please visit
                    our&nbsp;
                    <a
                      href="https://www.kwikpic.in/helpdesk"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Helpdesk
                    </a>
                  </div>
                </div>
              )}
            </div>
            <div className="contact-form col-md-6 mb-3">
              <ContactusForm />
            </div>
          </div>
        </Container>
        {ENV.WL_HIDE_HELPDESK ? null : (
          <Container>
            <div className="FAQ-mobile dark-gray-text thick-font pt-3">
              For technical issues or general queries, please visit our&nbsp;
              <a
                href="https://www.kwikpic.in/helpdesk"
                target="_blank"
                rel="noreferrer noopener"
              >
                Helpdesk
              </a>
            </div>
          </Container>
        )}
      </div>
      {(ENV.WL_APP_STORE_URL || ENV.WL_PLAY_STORE_URL) && (
        <section id="application-link">
          <ApplicationLink />
        </section>
      )}
      <ContactUsModal show={show} hide={() => setShow(false)} />
      <LandingPageFooter />
    </>
  );
};

export default ContactUs;
