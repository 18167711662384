import React from "react";
import cx from "classnames";
import css from "../css/Landing.module.css";
import ENV from "utils/helpers/env";

const PoweredBy = ({ variant = "light", className, join, centered }) => {
  const logo = variant === "light" ? "logo-white.png" : "logo.png";

  // return blank span to not mess up the layout
  if (ENV.WL_DISABLE_POWERED_BY) return <span />;

  return (
    <div
      className={cx(
        css[`credits-${variant}`],
        css.credits,
        className,
        centered ? css.centered : null,
        join ? css.join : null
      )}
    >
      <span>Powered by&nbsp;</span>
      <img
        src={`https://www.kwikpic.in/assets/images/global-images/${logo}`}
        alt="Kwikpic"
      />
    </div>
  );
};

export default PoweredBy;
