import React, { forwardRef, useImperativeHandle } from "react";
import UploadModal from "views/components/modals/UploadModal";
import UploadFromComputer from "views/components/modals/UploadFromComputer";
import UploadOneShot from "views/components/modals/UploadOneShot";
import UploadFromLink from "views/components/modals/UploadFromLink";
import UploadVideosModal from "views/components/modals/UploadVideosModal";
import YoutubeVideoUploadModal from "views/components/modals/YoutubeVideoUploadModal";
import NonChromeErrorModal from "./NonChromeErrorModal";
import { detectBrowser } from "views/download/helpers";
import { lsProxy } from "utils/helpers/localstorage";
import { toast } from "react-toastify";
import UserAgentErrorModal from "views/components/modals/UserAgentModal";
import useDetectUserAgent from "hooks/useDetectUserAgent";
import ENV from "utils/helpers/env";

export const UPLOAD_MODALS_TYPES = {
  NONE: "",
  SELECTION: "SELECTION",
  UPLOAD_FROM_COMPUTER: "UPLOAD_FROM_COMPUTER",
  UPLOAD_ONE_SHOT: "UPLOAD_ONE_SHOT",
  UPLOAD_FROM_LINK: "UPLOAD_FROM_LINK",
  UPLOAD_VIDEO: "UPLOAD_VIDEO",
  UPLOAD_YOUTUBE_VIDEO: "UPLOAD_YOUTUBE_VIDEO",
};

const GalleryUploader = forwardRef(
  ({ toggleGetEventStatusOfUploading }, ref) => {
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const [modal, setModal] = React.useState(UPLOAD_MODALS_TYPES.NONE);
    const [showNotChrome, setShowNotChrome] = React.useState(false);
    const [deviceType, setDeviceType] = React.useState({ type: "desktop" });
    useDetectUserAgent(setDeviceType);
    const handleShowChrome = () =>
      detectBrowser() !== "chrome" && setShowNotChrome(true);

    useImperativeHandle(ref, () => ({
      handleShow: () => {
        if (deviceType.type === "mobile") {
          setShowErrorModal(true);
        } else {
          toggleGetEventStatusOfUploading();
          setModal(UPLOAD_MODALS_TYPES.SELECTION);
        }
      },
    }));

    return (
      <>
        <UploadModal
          show={modal === UPLOAD_MODALS_TYPES.SELECTION}
          handleClose={() => window.location.reload()}
          handleTypeSelection={async (modalType) => {
            if (
              !(
                modalType === UPLOAD_MODALS_TYPES.UPLOAD_FROM_LINK ||
                modalType === UPLOAD_MODALS_TYPES.UPLOAD_YOUTUBE_VIDEO
              )
            )
              handleShowChrome();

            if (lsProxy.getItem("isUploading")) {
              toast.error(
                "One upload is already in progress. Please wait for that to finish"
              );
              if (lsProxy.getItem("uploadingFrom") === "Link") {
                setModal(UPLOAD_MODALS_TYPES.UPLOAD_FROM_LINK);
              }
              return;
            }
            setModal(modalType);
          }}
        />

        <UploadFromComputer
          show={modal === UPLOAD_MODALS_TYPES.UPLOAD_FROM_COMPUTER}
          handleClose={() => window.location.reload()}
        />

        <UploadOneShot
          show={modal === UPLOAD_MODALS_TYPES.UPLOAD_ONE_SHOT}
          handleClose={() => window.location.reload()}
        />

        <UploadFromLink
          show={modal === UPLOAD_MODALS_TYPES.UPLOAD_FROM_LINK}
          handleClose={() => setModal(UPLOAD_MODALS_TYPES.NONE)}
        />

        <UploadVideosModal
          show={modal === UPLOAD_MODALS_TYPES.UPLOAD_VIDEO}
          handleClose={() => window.location.reload()}
        />

        <YoutubeVideoUploadModal
          show={modal === UPLOAD_MODALS_TYPES.UPLOAD_YOUTUBE_VIDEO}
          handleClose={() => setModal(UPLOAD_MODALS_TYPES.NONE)}
        />

        <NonChromeErrorModal
          show={showNotChrome}
          hide={() => {
            setShowNotChrome(false);
          }}
        />

        <UserAgentErrorModal
          variant={
            ENV.WL_HAS_APP ? 0 : ENV.WL_FALLBACK_APP_DOWNLOAD_TO_KWIKPIC ? 3 : 4
          }
          show={showErrorModal}
          onHide={() => setShowErrorModal(false)}
          deviceType={deviceType}
        />
      </>
    );
  }
);
GalleryUploader.displayName = "GalleryUploader";

export default GalleryUploader;
