import React, { useEffect, useState } from "react";
import Masony from "react-masonry-component";
import { useSelector } from "react-redux";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Spinner from "views/components/loader/Spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getCFURL } from "utils/helpers";

const masonryOptions = {
  fitWidth: false,
  gutter: 0,
  itemSelector: ".photo-item",
  transitionDuration: 0,
};

export default function PortfolioImages({ folderName, closePortfolio }) {
  const [updatedImages, setUpdatedImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const [removeLoader, setRemoveLoader] = useState({});
  const [imageIndex, setImageIndex] = useState(-1);

  const { portfolio } = useSelector((state) => state?.folderImages) || {};
  const folder = portfolio.find((items) => items.folder === folderName);

  useEffect(() => {
    setUpdatedImages(folder?.images || []);
  }, [portfolio]);

  // const [imagesToBeRendered, setImagesToBeRendered] = useState(
  //   folder?.images || []
  // );

  // const LOAD_IMAGES_PER_TIME = 10;

  // useEffect(() => {
  //   let _imagesToBeRendered = [...(imagesToBeRendered || [])];

  //   if (_imagesToBeRendered?.length === 0) return;
  //   const observable = new Observable(async (observer) => {
  //     if (_imagesToBeRendered?.length === 0) {
  //       observer.complete();
  //     }
  //     let newImages = _imagesToBeRendered.splice(0, LOAD_IMAGES_PER_TIME);

  //     let imagesCount = 0;
  //     for (const image of newImages) {
  //       const img = new Image();
  //       // const compressedURL = getWebThumbnailURL(image.url);
  //       // const URL = await getCorrectURL(compressedURL);
  //       img.src = image.url;

  //       img.onload = function () {
  //         imagesCount += 1;
  //         newImages = newImages.map((_img) => {
  //           if (_img._id === image._id) {
  //             return {
  //               ..._img,
  //               webThumbWidth: img.width,
  //               webThumbHeight: img.height,
  //             };
  //           }
  //           return _img;
  //         });
  //         if (imagesCount === newImages.length) {
  //           observer.next({
  //             renderedImages: newImages,
  //             leftImages: _imagesToBeRendered,
  //           });
  //         }
  //       };

  //       img.onerror = () => {
  //         newImages = newImages.filter((_img) => _img._id !== image._id);
  //         if (imagesCount === newImages.length) {
  //           observer.next({
  //             renderedImages: newImages,
  //             leftImages: _imagesToBeRendered,
  //           });
  //         }
  //       };
  //     }
  //   });

  //   const subscription = observable.subscribe({
  //     next: ({ renderedImages, leftImages }) => {
  //       setUpdatedImages((prev) => [...(prev || []), ...renderedImages]);
  //       setImagesToBeRendered(leftImages);
  //     },
  //   });

  //   return () => {
  //     subscription.unsubscribe();
  //   };
  // }, [imagesToBeRendered, updatedImages]);

  return (
    <>
      <div className="header-container d-none d-md-flex align-items-center">
        <div className="backicon d-flex justify-content-around align-items-center mx-4">
          <img
            className="cursor-pointer me-2"
            onClick={closePortfolio}
            src="/assets/icons/backicon.svg"
          />
          {folderName}
        </div>
      </div>
      <img
        className="backbutton d-md-none cursor-pointer"
        src="/assets/images/icons/backbutton.png"
        alt="back button"
        onClick={closePortfolio}
      />
      <Masony
        options={masonryOptions}
        disableImagesLoaded={false}
        updateOnEachImageLoad={false}
        className="position-relative"
      >
        {updatedImages.length > 0 ? (
          (updatedImages || [])?.map((image, id) => {
            return (
              <li
                className={`photo-item`}
                style={{ width: `${100 / 4}%` }}
                key={id}
              >
                <LazyLoadImage
                  className="folder-imgs-image"
                  // id={"image_container-" + id}
                  key={id}
                  src={getCFURL({ url: image?.url, pThumb: true })}
                  style={{
                    height: "100%",
                    width: "100%",
                    minHeight: "250px",
                    transform: "scale(0)",
                    transition: "all .3s ease-out",
                  }}
                  onLoad={(e) => {
                    if (loading) {
                      setLoading(false);
                    }
                    setRemoveLoader((prev) => ({ ...prev, [id]: true }));
                    e.target.style.height = "100%";
                    e.target.style.width = "100%";
                    e.target.style.transform = "scale(1)";
                    e.target.style.minHeight = "auto";
                  }}
                  onError={(e) => {
                    e.target.src = getCFURL({ url: image?.url });
                  }}
                  onClick={() => {
                    setImageIndex(id);
                  }}
                />
                {!removeLoader[id] && (
                  <div
                    id={"loader-" + id}
                    className="gallery-img-spinner-loader"
                  />
                )}
              </li>
            );
          })
        ) : (
          <Spinner loading={true} />
        )}
      </Masony>

      {imageIndex !== -1 && (
        <Lightbox
          open={true}
          close={() => {
            setImageIndex(-1);
          }}
          plugins={[Zoom, Slideshow]}
          index={imageIndex}
          slides={
            folder?.images?.map((img) => ({
              src: img.url,
            })) || []
          }
          on={{
            view: ({ index }) => {
              setImageIndex(index);
            },
          }}
          carousel={{
            preload: 2,
          }}
          toolbar={{
            buttons: [
              <React.Fragment key={folderName}>
                <div>
                  <img
                    className="navigate_icons gallary-modal-back-button"
                    onClick={() => {
                      setImageIndex(-1);
                    }}
                    src="/assets/icons/arrow.svg"
                    alt="close"
                  />
                </div>

                <div className="image_count">
                  {`${imageIndex + 1}/${updatedImages.length}`}
                </div>
              </React.Fragment>,
            ],
          }}
          zoom={{
            maxZoomPixelRatio: 5,
            zoomInMultiplier: 2,
            doubleTapDelay: 300,
            doubleClickDelay: 300,
            doubleClickMaxStops: 2,
            keyboardMoveDistance: 50,
            wheelZoomDistanceFactor: 100,
            pinchZoomDistanceFactor: 100,
            scrollToZoom: true,
          }}
        />
      )}
    </>
  );
}
