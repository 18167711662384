import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SEND_USER_CLICK_ANALYTICS } from "redux-store/sagas/saga-actions";
import { setGroupLayout } from "redux-store/slices/group";
import PrimaryButton from "../../../components/button/PrimaryButton";
import SecondaryButton from "../../../components/button/SecondaryButton";
import ANALYTICS from "constants/analyticsKeys";
import "./PageHeading.css";

const PageHeading = ({ search, title, rightBar, link, handleShow }) => {
  const { groupLayout } = useSelector((state) => state.groups);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (open) {
      search?.("");
    }
    setOpen(!open);
  };

  return (
    <div className="pageHeading d-flex justify-content-between align-items-center">
      <div className="w-100 w-md-auto d-flex justify-content-between align-items-center onMobile-mb-2">
        <div className="d-flex align-items-center">
          {/*{!props.goBack &&*/}
          {/*    <img width="14" src="../../../assets/images/icons/back-arrow.png" alt="search"/>*/}
          {/*}*/}
          <h2 className="mb-0 pageHeading-title font-thick">{title}</h2>
        </div>

        <span className="for-mobile m-d-flex align-items-center">
          <div>
            {open && (
              <input
                onChange={(e) => search(e.target.value)}
                autoFocus
                className="searchBox"
              />
            )}
            <img
              src="/assets/images/icons/search.png"
              alt="search"
              onClick={() => {
                handleOpen();
                dispatch({
                  type: SEND_USER_CLICK_ANALYTICS,
                  payload: { buttons: [ANALYTICS.SEARCH] },
                });
              }}
            />
          </div>
          <div className="ms-2 cursor-pointer">
            {groupLayout === "grid" ? (
              <img
                src="/assets/images/icons/list.png"
                alt="list"
                onClick={() => dispatch(setGroupLayout("list"))}
              />
            ) : (
              <img
                src="/assets/images/icons/grid.png"
                alt="grid"
                onClick={() => dispatch(setGroupLayout("grid"))}
              />
            )}
          </div>
        </span>
      </div>
      {rightBar && (
        <div className="d-flex align-items-center pageHeadingButtons">
          <span className="for-pc">
            {open && (
              <input
                onChange={(e) => search(e.target.value)}
                autoFocus
                className="searchBox"
              />
            )}
            <img
              src="/assets/images/icons/search.png"
              onClick={() => {
                handleOpen();
                dispatch({
                  type: SEND_USER_CLICK_ANALYTICS,
                  payload: { buttons: [ANALYTICS.SEARCH] },
                });
              }}
              alt="search"
            />
          </span>
          <PrimaryButton
            title="Join a group"
            isButton={true}
            onClick={() => {
              dispatch({
                type: SEND_USER_CLICK_ANALYTICS,
                payload: { buttons: [ANALYTICS.JOIN_GROUP] },
              });
              navigate(link);
            }}
          />
          <div className="for-pc me-2"></div>
          <SecondaryButton title="Create a group" onClick={handleShow} />
          <span className="for-pc cursor-pointer">
            {groupLayout === "grid" ? (
              <img
                src="/assets/images/icons/list.png"
                alt="list"
                onClick={() => dispatch(setGroupLayout("list"))}
              />
            ) : (
              <img
                src="/assets/images/icons/grid.png"
                alt="grid"
                onClick={() => dispatch(setGroupLayout("grid"))}
              />
            )}
          </span>
        </div>
      )}
    </div>
  );
};
export default PageHeading;
