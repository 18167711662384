import React from "react";
import { Modal } from "react-bootstrap";
import ENV from "utils/helpers/env";
import SecondaryButton from "views/components/button/SecondaryButton";

/**
 * @typedef {Object} UserAgentErrorModalProps
 * @property {boolean} show
 * @property {function} onHide
 * @property {{os:"ios"|"android",type:"desktop"|"mobile"}} deviceType
 * @property {number} [variant=0]
 * - 0: wl has app, display them
 * - 1: this feature is not supported on mobile, no download link is displayed
 * - 2: this feature is not supported on desktop, 1 button is displayed on phone, 2 on desktop
 * - 3: wl doesn't have app, but wants to display kwikpic app links
 * - 4: wl doesn't have app, and doesn't want to display kwikpic app links
 * @property {string} [textOverride=""]
 * @param {UserAgentErrorModalProps} props
 */
const UserAgentErrorModal = ({
  show,
  onHide,
  deviceType,
  variant = 0,
  textOverride = "",
}) => {
  const business = ENV.WL_BUSINESS_NAME;
  const prop = [
    // wl has app
    {
      text: `Download the ${business} App or use the ${business} Desktop Website to enable this feature.`,
      image: "/assets/images/gallary/no-download-mobile-alt.png",
    },
    // this feature is not supported on mobile
    {
      text: `Open the ${business} website on a computer or laptop to access this feature.`,
      image: "/assets/images/gallary/no-download-alt.png",
    },
    // this feature is not supported on desktop
    {
      text: `Download the ${business} App to use this feature.`,
      image: "/assets/images/gallary/mobile-alt.png",
    },
    // wl doesnt have app, but wants to display kwikpic app links
    {
      text: `Download the Kwikpic Mobile App or use the ${business} Desktop Website to enable this feature.`,
      image: "/assets/images/gallary/no-download-mobile-alt.png",
    },
    // wl doesn't have app, and doesn't want to display kwikpic app links
    {
      text: `Open the ${business} website on a computer or laptop to access this feature.`,
      image: "/assets/images/gallary/no-download-alt.png",
    },
  ];

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0 d-none" />
      <Modal.Body>
        <div className="user-agent-modal">
          <img src={prop[variant].image} />
          <p className="bold-font font-15 my-3">
            {textOverride || prop[variant].text}
          </p>
          <DownloadButtons
            variant={variant}
            deviceType={deviceType}
            onHide={onHide}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

/**
 * @typedef {Object} DownloadButtonsProps
 * @property {UserAgentErrorModalProps["variant"]} variant
 * @property {UserAgentErrorModalProps["deviceType"]} deviceType
 * @property {string} [className]
 * @property {boolean} [showKwikpic]
 * @property {function} onHide
 * @param {DownloadButtonsProps} props
 */
export const DownloadButtons = ({
  variant,
  deviceType,
  className,
  showKwikpic,
  onHide,
}) => {
  const iosURL =
    showKwikpic || variant === 3
      ? process.env.REACT_APP_KW_IOS
      : ENV.WL_APP_STORE_URL || ENV.WL_APP_SHORT_URL;

  const androidURL =
    showKwikpic || variant === 3
      ? process.env.REACT_APP_KW_ANDROID
      : ENV.WL_PLAY_STORE_URL || ENV.WL_APP_SHORT_URL;

  return (
    <>
      {variant === 0 ||
      (deviceType?.type === "mobile" && (variant === 2 || variant === 3)) ? (
        <SecondaryButton
          title="Download App"
          style={{ width: "100%" }}
          className={className}
          onClick={() => {
            if (!iosURL && !androidURL) return onHide();

            window.open(
              deviceType?.os === "ios" ? iosURL : androidURL,
              "_blank"
            );
          }}
        />
      ) : variant === 2 || variant === 3 ? (
        <div className="ua-store-links">
          <img
            role="button"
            src="/assets/images/landing/appstore-badge.png"
            alt="apple store link"
            onClick={() => (iosURL ? window.open(iosURL) : onHide())}
          />
          <img
            role="button"
            src="/assets/images/landing/google-play-badge-logo.png"
            alt="play store link"
            onClick={() => (androidURL ? window.open(androidURL) : onHide())}
          />
        </div>
      ) : null}
    </>
  );
};

export default UserAgentErrorModal;
