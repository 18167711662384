import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { modifyGallaryRoute } from "utils/helpers/routes";
import { getLocalStorageUser } from "utils/helpers/localstorage";

export default function useGalleryRouteManager() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { userType } = getLocalStorageUser();
  const { pathname } = useLocation();

  const hasGroupId = params.has("groupId");

  useEffect(() => {
    if (!hasGroupId) {
      navigate("/groups", { replace: true });
      return;
    }

    const isFolderView = pathname === "/gallery/folders";
    const isUser = userType === "USER";
    const defaultFolder = isUser ? "my-photos" : "all-photos";
    const hasPage = params.has("page");
    const hasFolderID = params.has("folderId");
    let page;
    let folderId;

    const groupId = params.get("groupId");

    if (hasPage) {
      page = params.get("page");
      if (isNaN(Number(page))) {
        page = 1;
      }
    } else {
      page = 1;
    }

    if (hasFolderID) {
      folderId = params.get("folderId");
    } else {
      folderId = defaultFolder;
    }

    navigate(
      modifyGallaryRoute(
        { pageNo: page, folderId, groupId, isFolderView },
        params
      ),
      { replace: true }
    );
  }, [params, pathname]);
}
