import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import SettingPageHeading from "../components/SettingPageHeading";
import css from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { GET_USER_WALLET } from "redux-store/sagas/saga-actions";
import ConfirmationModal from "views/components/modals/ConfirmationModal";
import AddCredits from "./components/AddCredit";

const WalletSettings = () => {
  const [params] = useSearchParams();
  const token = params.has("token");
  const noback = params.has("noback");

  const { wallet: { userWallet, conversionRate } = {} } = useSelector(
    (state) => state.settings
  );
  const { paymentResponse, paymentsCurrency } = useSelector(
    (state) => state.globals
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({
      type: GET_USER_WALLET,
    });
  }, []);

  let conversionText;

  switch (paymentsCurrency) {
    case "INR":
      conversionText = `₹1 = ${conversionRate?.oneRupeeToCredits} credits`;
      break;
    case "USD":
      conversionText = `$1 = ${conversionRate?.oneDollarToCredits} credits`;
      break;
    case "GBP":
      conversionText = `£1 = ${conversionRate?.onePoundToCredits} credits`;
      break;
  }

  return (
    <Container fluid className="g-0">
      <Row>
        <Col>
          <SettingPageHeading
            title="Kwikpic Wallet"
            goBack={token && !noback ? () => history.back() : false}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={5}>
          <div className="my-4">
            <h3>Balance</h3>
            <div className={css["balance-card"]}>
              <p className={css.balance}>
                {Math.round(userWallet?.credits * 100) / 100} credits
              </p>
              <p className="color-h font-15 mb-2 d-flex align-items-center gap-1">
                <img
                  src="/assets/images/icons/info-plain.png"
                  alt="info plain"
                  className="info-icon"
                />
                <span>{conversionText}</span>
              </p>
            </div>
          </div>
          <div className="dropdown-divider" />
          <AddCredits className="my-4" />
          <div>
            <p className="font-15 text-black">
              For any queries about your wallet, kindly&nbsp;
              <a
                href="https://www.kwikpic.in/helpdesk"
                className="text-decoration-underline"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </p>
          </div>
        </Col>
        <Col xs={12} lg={6} className="ms-lg-4">
          <div className="dropdown-divider d-md-none d-block" />
          <div className="my-4">
            <h3 className="d-flex align-items-center gap-1">
              <img
                src="/assets/images/icons/info-plain.png"
                alt="info plain"
                className="info-icon"
              />
              How to use credits
            </h3>
            <div className={css.box}>
              <p className="font-15 font-normal text-black mb-2">
                Notify participants every time you upload new photos via:
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <img
                        src="/assets/images/icons/notifications/sms.png"
                        alt="sms"
                      />
                    </td>
                    <td>SMS:</td>
                    <td>0.5 credit/notification</td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src="/assets/images/icons/notifications/whatsapp.png"
                        alt="whatsapp"
                      />
                    </td>
                    <td>WhatsApp:</td>
                    <td>1 credit/notification</td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src="/assets/images/icons/notifications/email.png"
                        alt="email"
                      />
                    </td>
                    <td>Email:</td>
                    <td>0.2 credit/notification</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={css.box}>
              <p className="font-15 text-black font-normal mb-2">
                Get or renew Kwikpic subscriptions using these credits
              </p>
              <p className="m-0">
                <a
                  href="https://www.kwikpic.in/landing/contactus"
                  target="_blank"
                  className="text-decoration-underline font-15"
                  rel="noreferrer"
                >
                  Contact Support
                </a>
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <ConfirmationModal
        show={paymentResponse?.title}
        onCancel={() => window.location.reload()}
        title={paymentResponse?.title}
        footer={false}
        animation={true}
      >
        <p className="text-center font-15 mb-3">{paymentResponse?.details}</p>
      </ConfirmationModal>
    </Container>
  );
};

export default WalletSettings;
