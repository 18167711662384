import React from "react";
import { Container } from "react-bootstrap";
import ENV from "utils/helpers/env";

const ApplicationLink = () => {
  const storePhoto = [
    {
      name: "App Store",
      image: "/assets/images/landing/appstore-badge.png",
      link: ENV.WL_APP_STORE_URL,
    },
    {
      name: "Play Store",
      image: "/assets/images/landing/google-play-badge-logo.png",
      link: ENV.WL_PLAY_STORE_URL,
    },
  ];

  return (
    <div className="introduce-bg-color">
      <Container className="applicaiton-link">
        <div className="try-app bottom-margin-32">
          Try the {ENV.WL_BUSINESS_NAME} App for Free!
        </div>
        <div className="download-now mb-3">Download Now!</div>
        <div className="app-links">
          {storePhoto
            .filter((i) => i.link)
            .map((store, index) => (
              <div className="play-store" key={index}>
                <img
                  className="cursor-pointer"
                  src={store.image}
                  alt={store.name}
                  onClick={() => {
                    window.open(store.link, "_blank");
                  }}
                />
              </div>
            ))}
        </div>
      </Container>
    </div>
  );
};

export default ApplicationLink;
