import React, { useEffect } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import ENV from "utils/helpers/env";

const AuthRoute = () => {
  const { goBackType } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(() => {
    if (goBackType === "/auth/login" && location.pathname === "/auth/otp") {
      navigate("/auth/login");
    }
  }, [location, goBackType]);

  return (
    <div className="authScreen">
      {!params.get("uCode") && (
        <div className="mobileHeader">
          <Navbar.Brand href="/">
            <img src={ENV.WL_PRIMARY_LOGO} alt="logo" />
          </Navbar.Brand>
        </div>
      )}
      <Outlet />
    </div>
  );
};

export default AuthRoute;
