import React from "react";
import "./App.css";
import AuthRoute from "./views/routes/AuthRoute";
import Login from "./views/auth/photographer/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Utilities.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import MainRoute from "./views/routes/MainRoute";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./views/main/home/Home";
import SettingsRoute from "./views/routes/SettingsRoute";
import GalleryRoute from "./views/routes/GalleryRoute";
import OtpScreen from "./views/auth/photographer/OtpScreen";
import SelectUser from "./views/auth/photographer/SelectUser";
import Register from "./views/auth/photographer/Register";
import GeneralSettings from "./views/settings/general";
import PrivacySettings from "./views/settings/privacy";
import Participants from "views/settings/participants";
import Folders from "./views/settings/Folders";
import DownloadSettings from "./views/settings/DownloadSettings";
import ClientFavourite from "./views/settings/ClientFavourite";
import ProfileSettings from "./views/settings/profile";
import BusinessSettings from "./views/settings/business";
import JoinGroup from "./views/main/joinGroup/JoinGroup";
import LandingRoute from "./views/routes/LandingRoute";
import ContactUs from "./views/contactUs/ContactUs";

import AnonymousSelfies from "views/anonymous/anonymousSelfies";
import AnonymousGallery from "views/anonymous/anonymousGallery";
import AnonymousDownloadRoute from "views/routes/AnonymousDownloadRoute";

import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "views/routes/styles/pricingList.css"; // tmp fix for wl, remove when new pricing page is ready

import SettingsMobilePage from "./views/components/sidebar/SettingsMobilePage";
import LoginViaLink from "./views/auth/user/LoginViaLink";
import ClickSelfie from "./views/auth/user/ClickSelfie";
import DesignSettings from "./views/settings/DesignSettings";
import RegisterUser from "./views/auth/user/RegisterUser";
import DownloadRoutes from "views/routes/DownloadRoutes";
import SettingsBusinessMobilePage from "views/components/sidebar/SettingsBusinessMobilePage";
import JoinGroupLanding from "views/main/joinGroup/JoinGroupLanding";
import InvalidGroupLink from "views/main/joinGroup/InvalidGroupLink";
import NotFound from "views/notFound/NotFound";
import Watermark from "views/settings/watermark";
import PrivacySecurityRoute from "views/routes/PrivacySecurityRoute";
import PrivacySecurity from "views/privacySecurity/PrivacySecurity";
import WalletSettings from "views/settings/wallet";
import PhotographerLanding from "views/landing/PhotographerLanding";

const DigitalAlbum = React.lazy(() => import("views/settings/digital-album"));
const AlbumSettings = React.lazy(() => import("views/settings/album"));

import SuspenseLoader from "views/components/loader/SuspenseLoader";
import Search from "views/components/search";
import ENV from "utils/helpers/env";

import MobileSupport from "views/mobile/MobileSupport";

const TeamLogin = React.lazy(() => import("views/settings/team-login"));
const Sponsors = React.lazy(() => import("views/settings/sponsors"));
const PortfolioSettings = React.lazy(() => import("views/settings/portfolio"));

const PhotographerPortfolio = React.lazy(() =>
  import("views/photographerDashboard/PhotographerPortfolio")
);

const UpdateProfile = React.lazy(() => import("views/main/update-profile"));

const AnalyticsRoute = React.lazy(() => import("views/routes/AnalyticsRoute"));
const Analytics = React.lazy(() => import("views/main/analytics/Analytics"));

const SellPhotos = React.lazy(() => import("views/settings/sell-photos"));
const SellPhotosCheckout = React.lazy(() => import("views/gallary/checkout"));
const Transfer = React.lazy(() => import("views/transfer/Transfer"));

const PolicyRoutes = React.lazy(() => import("views/routes/PolicyRoutes"));
const TnC = React.lazy(() => import("views/policy/TnC"));
const PrivacyPolicy = React.lazy(() => import("views/policy/PrivacyPolicy"));
const Refund = React.lazy(() => import("views/policy/Refund"));

const Notifications = React.lazy(() => import("views/main/notifications"));

import WarnDisabledCookies from "views/components/modals/WarnDisabledCookies";
import useGetCountry from "hooks/useGetCountry";
// import useNotifications from "hooks/useNotifications";
import Gallery from "views/gallary";

const App = () => {
  useGetCountry();
  // useNotifications();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        closeOnClick
        rtl={false}
        enableMultiContainer
      />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        pauseOnHover={true}
        pauseOnFocusLoss={false}
        closeOnClick
        rtl={false}
        enableMultiContainer
        containerId="notifications"
      />

      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar
        transition={Slide}
        containerId="infos"
        enableMultiContainer
      />

      <BrowserRouter>
        <Routes>
          <Route path="/" name="landing" element={<LandingRoute />}>
            <Route index element={<PhotographerLanding />} />
            <Route path="join" name="landing" element={<JoinGroupLanding />} />
            <Route path="contact" element={<ContactUs />} />
          </Route>

          <Route path="/mobile-support" element={<MobileSupport />} />

          <Route path="/update-profile" element={<MainRoute />}>
            <Route
              index
              element={
                <React.Suspense fallback={<SuspenseLoader />}>
                  <UpdateProfile />
                </React.Suspense>
              }
            />
          </Route>

          <Route path="/portfolio" element={<Outlet />}>
            <Route
              index
              element={
                <React.Suspense fallback={<SuspenseLoader />}>
                  <PhotographerPortfolio />
                </React.Suspense>
              }
            />
            {ENV.WL_DISTRIBUTOR && ENV.WL_SHOW_MUTIPLE_BRANDING ? (
              <Route
                path="/portfolio/:portfolioId"
                element={
                  <React.Suspense fallback={<SuspenseLoader />}>
                    <PhotographerPortfolio />
                  </React.Suspense>
                }
              />
            ) : (
              <Route path="*" element={<Navigate to="/portfolio" />} />
            )}
          </Route>

          <Route path="/groups" name="home" element={<MainRoute />}>
            <Route index element={<Home />} />
            <Route path="join" element={<JoinGroup />} />
          </Route>

          <Route path="/notifications" element={<MainRoute />}>
            <Route
              index
              element={
                <React.Suspense fallback={<SuspenseLoader />}>
                  <Notifications />
                </React.Suspense>
              }
            />
          </Route>

          <Route path="/invalid-group-link" element={<InvalidGroupLink />} />

          <Route path="/auth" name="auth" element={<AuthRoute />}>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/otp" element={<OtpScreen />} />
            <Route path="/auth/select-user" element={<SelectUser />} />
            <Route path="/auth/register" element={<Register />} />
            <Route path="/auth/register-user" element={<RegisterUser />} />
            <Route path="/auth/login-link" element={<LoginViaLink />} />
            <Route path="/auth/click-selfie" element={<ClickSelfie />} />
          </Route>

          <Route path="/anonymous" name="anonymous" element={<Outlet />}>
            <Route path="anonymous-selfies" element={<AnonymousSelfies />} />
            <Route
              path="gallery/download"
              element={<AnonymousDownloadRoute />}
            />
            <Route path="gallery" element={<AnonymousGallery />} />
          </Route>

          <Route path="/settings" name="settings" element={<SettingsRoute />}>
            <Route path="/settings/list" element={<SettingsMobilePage />} />
            <Route path="/settings/general" element={<GeneralSettings />} />
            <Route path="/settings/privacy" element={<PrivacySettings />} />
            <Route path="/settings/participants" element={<Participants />} />
            <Route path="/settings/folders" element={<Folders />} />
            {/* <Route
              path="/settings/delete-request"
              element={<DeleteRequests />}
            /> */}
            <Route path="/settings/design" element={<DesignSettings />} />
            <Route path="/settings/download" element={<DownloadSettings />} />
            <Route
              path="/settings/flipbook"
              element={
                <React.Suspense fallback={<SuspenseLoader />}>
                  <DigitalAlbum />
                </React.Suspense>
              }
            />
            <Route path="/settings/client" element={<ClientFavourite />} />
            <Route
              path="/settings/sponsors"
              element={
                <React.Suspense fallback={<SuspenseLoader />}>
                  <Sponsors />
                </React.Suspense>
              }
            />
            <Route
              path="/settings/sell-photos"
              element={
                <React.Suspense fallback={<SuspenseLoader />}>
                  <SellPhotos />
                </React.Suspense>
              }
            />
          </Route>

          <Route
            path="/profile-settings"
            name="businessSettings"
            element={<SettingsRoute />}
          >
            <Route
              path="/profile-settings/list"
              element={<SettingsBusinessMobilePage />}
            />
            <Route
              path="/profile-settings/profile"
              element={<ProfileSettings />}
            />
            <Route
              path="/profile-settings/business"
              element={<BusinessSettings />}
            />
            <Route path="/profile-settings/watermark" element={<Watermark />} />
            <Route
              path="/profile-settings/portfolio"
              element={
                <React.Suspense fallback={<SuspenseLoader />}>
                  <PortfolioSettings />
                </React.Suspense>
              }
            />
            <Route
              path="/profile-settings/wallet"
              element={<WalletSettings />}
            />
            <Route
              path="/profile-settings/flipbook"
              element={
                <React.Suspense fallback={<SuspenseLoader />}>
                  <AlbumSettings />
                </React.Suspense>
              }
            />
            <Route
              path="/profile-settings/team-login"
              element={
                <React.Suspense fallback={<SuspenseLoader />}>
                  <TeamLogin />
                </React.Suspense>
              }
            />
          </Route>

          <Route
            path="/privacy-security"
            name="privacy-security"
            element={<PrivacySecurityRoute />}
          >
            <Route index element={<PrivacySecurity />} />
          </Route>

          <Route
            path="/analytics"
            name="analytics"
            element={
              <React.Suspense fallback={<SuspenseLoader />}>
                <AnalyticsRoute />
              </React.Suspense>
            }
          >
            <Route index element={<Analytics />} />
          </Route>

          <Route path="/gallery" element={<GalleryRoute />}>
            <Route index element={<Gallery />} />
            <Route path="folders" element={<Gallery />} />
            <Route path="download" element={<DownloadRoutes />} />
            <Route
              path="checkout"
              element={
                <React.Suspense fallback={<SuspenseLoader />}>
                  <SellPhotosCheckout />
                </React.Suspense>
              }
            />
          </Route>

          <Route
            path="/policy"
            name="policy"
            element={
              <React.Suspense fallback={<SuspenseLoader />}>
                <PolicyRoutes />
              </React.Suspense>
            }
          >
            <Route path="terms-and-conditions" element={<TnC />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="refund" element={<Refund />} />
          </Route>

          <Route
            path="/transfer"
            name="transfer"
            element={
              <React.Suspense fallback={<SuspenseLoader />}>
                <Transfer />
              </React.Suspense>
            }
          />

          {/* 404 route */}
          <Route path="/not-found" name="not found" element={<NotFound />} />
          <Route path="*" name="not found" element={<NotFound />} />
        </Routes>
        {!ENV.WL_HIDE_HELPDESK && <Search />}
        <WarnDisabledCookies />
      </BrowserRouter>
    </>
  );
};

export default App;
