import React, { Suspense, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PageHeading from "../components/pageHeading/PageHeading";
import GroupView from "./components/GroupView";
import { useDispatch } from "react-redux";
import {
  GET_MY_GROUPS,
  SEND_USER_CLICK_ANALYTICS,
} from "../../../redux-store/sagas/saga-actions";
import { useSelector } from "react-redux";
import Spinner from "../../components/loader/Spinner";
import { setUserFirstTime } from "redux-store/slices/user";
import useSubscription from "views/subscription/hooks/useSubscription";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import useDetectUserAgent from "hooks/useDetectUserAgent";
import { useLocation } from "react-router-dom";
import PhotographerErrorModal from "views/components/modals/PhotographerError";
import SuspenseLoader from "views/components/loader/SuspenseLoader";
import ENV from "utils/helpers/env";
import ANALYTICS from "constants/analyticsKeys";

const EmptyGroup = React.lazy(() => import("./components/EmptyGroup"));
const CreateGroupModal = React.lazy(() =>
  import("views/components/modals/CreateGroupModal")
);
const HomePageInfoModals = React.lazy(() =>
  import("./components/HomePageInfoModals")
);

const MODAL_TYPES = {
  CREATE_GROUP: "createGroup",
  ERROR: "error",
  NONE: null,
};

const Home = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(MODAL_TYPES.NONE);
  const [currentPage, setCurrentPage] = useState({ page: 1, limit: 20 });
  const [search, setSearch] = useState("");
  const [deviceType, setDeviceType] = useState({ type: "desktop" });
  const { groups, count, initialLoader } = useSelector((state) => state.groups);
  const { firstTime } = useSelector((state) => state.user);

  const { getMySubscriptionPlanAPI } = useSubscription();
  const { _id } = getLocalStorageUser();
  useDetectUserAgent(setDeviceType);
  const { state } = useLocation();

  const handleShow = () => {
    if (_id !== ENV.WL_USERID) return setShowModal(MODAL_TYPES.ERROR);

    setShowModal(MODAL_TYPES.CREATE_GROUP);

    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.CREATE_GROUP] },
    });
  };

  const handleSearch = (val) => {
    setSearch(val);
  };

  useEffect(() => {
    dispatch({
      type: GET_MY_GROUPS,
      payload: currentPage,
    });

    getMySubscriptionPlanAPI();

    if (state?.isJoinFailed) {
      setShowModal(MODAL_TYPES.ERROR);
    }
  }, [dispatch]);

  const hasNoGroups = groups?.length === 0 && !initialLoader;

  return (
    <Container>
      <Spinner loading={initialLoader} />
      <div className="homePage">
        <PageHeading
          title="Groups"
          link="/groups/join"
          handleShow={handleShow}
          rightBar={true}
          search={handleSearch}
        />
        {hasNoGroups ? (
          <Suspense fallback={<SuspenseLoader />}>
            <EmptyGroup />
          </Suspense>
        ) : (
          <GroupView
            setCurrentPage={setCurrentPage}
            groups={groups}
            count={count}
            currentPage={currentPage}
            search={search}
            thumb="/assets/images/groups/default-group-logo.png"
          />
        )}
        <Suspense fallback={<SuspenseLoader />}>
          {showModal === MODAL_TYPES.CREATE_GROUP && (
            <CreateGroupModal
              show={true}
              handleClose={() => setShowModal(MODAL_TYPES.NONE)}
            />
          )}

          {showModal === MODAL_TYPES.ERROR && (
            <PhotographerErrorModal
              show={true}
              hide={() => {
                setShowModal(MODAL_TYPES.NONE);
                history.replaceState(null, null, "/groups");
                if (firstTime) dispatch(setUserFirstTime(false));
              }}
              deviceType={deviceType}
              type={state?.isJoinFailed ? "join" : "create"}
            />
          )}

          {!showModal && <HomePageInfoModals />}
        </Suspense>
      </div>
    </Container>
  );
};

export default Home;
