import useJoinGroupInfo from "hooks/useJoinGroupInfo";
import React from "react";
import { useState } from "react";
import { Form, Navbar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  // GET_GROUP_DETAILS,
  // GET_GROUP_DETAILS,
  JOIN_MY_GROUP,
} from "redux-store/sagas/saga-actions";
// import { setUserFirstTime } from "redux-store/slices/user";
import DefaultButton from "views/components/button/DefaultButton";
import DefaultInput from "views/components/input/DefaultInput";
import Spinner from "views/components/loader/Spinner";
import "./css/joinGroupLanding.css";
import AuthTextLeft from "views/auth/components/AuthTextLeft";
import { lsProxy } from "utils/helpers/localstorage";
import ENV from "utils/helpers/env";

const JoinGroupLanding = () => {
  const [uCode, setuCode] = useState("");
  // const groupDetails = useSelector((state) => state.groups.group?.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const joinGroup = useSelector((state) => state.groups);
  // const [groupId, setGroupId] = useState(null);
  const { loading: joinGroupLoading, joinNewGroupSocketInit } =
    useJoinGroupInfo();

  // useEffect(() => {
  //   const token = lsProxy.getItem("token");
  //   if (token && uCode) {
  //     if (joinGroup?.joinGroupResponse) {
  //       if (
  //         joinGroup?.joinGroupResponse?.status === 200 &&
  //         joinGroup?.joinGroupResponse?.message === "Group joined successfully"
  //       ) {
  //         // dispatch(setUserFirstTime(true));
  //         // navigate(
  //         //   "/gallery?groupId=" + joinGroup?.joinGroupResponse?.groupId,
  //         //   {
  //         //     state: {
  //         //       joined: true,
  //         //     },
  //         //   }
  //         // );
  //       }
  //       if (
  //         joinGroup?.joinGroupResponse?.status === 400 &&
  //         joinGroup?.joinGroupResponse?.message ===
  //           "You are already group participant"
  //       ) {
  //         dispatch(setUserFirstTime(true));
  //         navigate("/gallery?groupId=" + groupId, {
  //           state: {
  //             joined: true,
  //           },
  //         });
  //       }
  //     }
  //   }
  // }, [joinGroup]);

  // React.useEffect(() => {
  //   if (groupDetails?.groupId) {
  //     joinNewGroupSocketInit().then(() => {
  //       dispatch({
  //         type: JOIN_MY_GROUP,
  //         groupCode: uCode,
  //       });
  //     });
  //   }
  // }, [groupDetails?.groupId]);

  const submitUcode = async (e) => {
    e.preventDefault();
    if (uCode === null) {
      toast.error("Please Enter Group Code");
      return;
    }
    const token = lsProxy.getItem("token");

    if (uCode && !token) {
      navigate(`/auth/login?uCode=${uCode}`);
    }
    if (uCode && token) {
      // dispatch({
      //   type: GET_GROUP_DETAILS,
      //   groupCode: uCode,
      // });
      // // setTimeout(async () => {
      await joinNewGroupSocketInit();
      dispatch({
        type: JOIN_MY_GROUP,
        groupCode: uCode,
      });
      // // }, 1000);
    }
  };

  return (
    <>
      <div className="mobileHeader">
        <Navbar.Brand href="/">
          <img src={ENV.WL_PRIMARY_LOGO} alt="logo" />
        </Navbar.Brand>
      </div>
      <div className="d-flex align-items-center">
        {joinGroupLoading && <Spinner loading={joinGroupLoading} />}

        <AuthTextLeft
          class="col7 position-relative"
          width="430px"
          text={ENV.WL_JOIN_QUOTE}
          image={ENV.WL_JOIN_BANNER}
        />
        <div className="col5">
          <div className="authFormContainer">
            <div className="authHeader"></div>
            <div className="authFormCard">
              <div>
                <h2>Join a Group</h2>
                <span>Enter the 6-digit Unique Code of the Group</span>
              </div>
              <Form onSubmit={submitUcode}>
                <div className="mt-3">
                  <DefaultInput
                    name="formBasicEmail"
                    placeholder="UCode"
                    value={uCode}
                    onChange={(e) => {
                      setuCode(e.target.value.toUpperCase());
                    }}
                  />
                </div>
                <div className="mt-3"></div>
                <DefaultButton title="Continue" type="submit" />
              </Form>
            </div>
            <div className="authFooter"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinGroupLanding;
