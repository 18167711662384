import React, { useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import ProgressCard from "../progressBar/ProgressBar";
import SmallRoundedButton from "../button/SmallRoundedButton";
import SecondarySmallBtn from "../button/SecondarySmallBtn";
import PrimarySmallBtn from "../button/PrimarySmallBtn";
import { useSearchParams } from "react-router-dom";
import DefaultButton from "../button/DefaultButton";
import Spinner from "../loader/Spinner";
import { useSelector } from "react-redux";
import { AutoSizer, List } from "react-virtualized";
import useUploadFromComputer, {
  imagesStatus,
} from "../../../hooks/useUploadFromComputer";
import { bytesToSize } from "utils/helpers";
import ConfirmationModal from "./ConfirmationModal";
import AddFolder from "./components/uploadfromcomputer/AddFolder";
import Dropzone from "react-dropzone";
import cx from "classnames";
// import { DEFAULT_SORT, sortTypes } from "./UploadOneShot";
import css from "./css/Uploads.module.css";
import StorageFullModal from "./StorageFullModal";
import SubscriptionModal from "views/subscription";
// import { GET_USER_ANALYTICS } from "redux-store/sagas/saga-actions";
import { storageFullSlackMessage } from "utils/helpers/subscription";
import sendSlackMessage from "utils/helpers/sendSlackMessage";
import { videoStatus } from "hooks/useUploadVideo";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import { SORT_TYPES } from "./UploadOneShot";

export const STATIC_FOLDERS = {
  ALL_PHOTOS: "All Photos",
  HIGHLIGHTS: "Highlights",
  GUEST_UPLOADS: "Guest Uploads",
};

const SORT_STRATEGIES = {
  [SORT_TYPES.ASC]: "/assets/images/icons/sorting/atoz",
  [SORT_TYPES.DESC]: "/assets/images/icons/sorting/ztoa",
};

const UploadFromComputer = (props) => {
  const {
    groupDetails: { folders, isAdmin },
    settingLoader,
    privacySettings: { guestFolderOnly },
  } = useSelector((state) => state.settings);
  const [params] = useSearchParams();
  const { userAnalytics } = useSelector((state) => state.settings);
  const [allFolder, setAllFolder] = useState([]);
  const [subscriptionModal, setSubscriptionModal] = useState({
    subscription: false,
    storage: false,
  });
  const dropRef = React.useRef(null);

  const {
    successFullySentToBackend,
    totalImagesSize,
    handleImageUploads,
    startUploading,
    isUploading,
    loading,
    allUploadedImages,
    hasUploadedAllImages,
    handleConfirmationModal,
    confirmationModal,
    successfullyUploaded,
    imagesWithError,
    duplicateImages,
    folderPrefix,
    setFolderPrefix,
    handleFailedUploads,
    handleSortBy,
    sortByRef,
  } = useUploadFromComputer({
    params,
    handleClose: props.handleClose,
    show: props.show,
  });

  React.useEffect(() => {
    if (folders) {
      setAllFolder(folders);
    }
  }, [folders]);

  const {
    photoUploads,
    highResPhotoUploads,
    uploadLimit,
    totalUtilisation,
    maxPhotoUpload,
  } = userAnalytics || {};
  const { highRes } = getLocalStorageUser();

  const oneXCondition =
    totalUtilisation +
      (allUploadedImages.length - duplicateImages.length) *
        (highRes ? 2.5 : 1) >
    uploadLimit;

  const limit = maxPhotoUpload ? maxPhotoUpload : uploadLimit * 2;
  const twoXCondition =
    photoUploads +
      highResPhotoUploads * 2.5 +
      (allUploadedImages.length - duplicateImages.length) *
        (highRes ? 2.5 : 1) >
    limit;

  const storageCon = oneXCondition || twoXCondition;

  const _allFolders = React.useMemo(() => {
    return allFolder.filter(
      (folder) => folder.folderPrefix !== STATIC_FOLDERS.HIGHLIGHTS
    );
  }, [allFolder]);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) return;

    handleImageUploads(acceptedFiles);
  };

  const { designSettings } = useSelector((state) => state.settings);
  const { font } = designSettings;

  const handleStorageFull = () => {
    setSubscriptionModal((p) => ({
      ...p,
      storage: true,
    }));
    const msg = storageFullSlackMessage(allUploadedImages.length);
    sendSlackMessage(msg, "#upload-exceed");
  };

  return (
    <>
      <Modal
        centered
        show={props.show}
        onHide={handleConfirmationModal}
        animation={true}
        backdrop="none"
        className={cx(
          "uploadFromComputer",
          font === 1 ? "fontSerif" : "fontSans"
        )}
      >
        <Modal.Header
          closeButton={true}
          className="justify-content-start justify-content-md-center border-0 position-relative w-100"
        >
          <Modal.Title>
            <p className="font-bold font-24 mb-0">Upload Photos</p>
            {highRes && (
              <p className="font-12 font-md-13 color-green base-font position-absolute end-0 top-50 translate-middle-y m-0 me-5">
                <img
                  src="/assets/images/icons/info-green.png"
                  alt="info plain"
                  className="info-icon"
                />
                &nbsp;High Resolution mode is ON
                <br />1 High-Res Upload = 2.5 Photos
              </p>
            )}
          </Modal.Title>

          {/* {allUploadedImages.length > 0 && (
            <div
              className="d-flex flex-column align-items-center"
              style={{
                position: "absolute",
                top: "2%",
                left: "2%",
              }}
            >
              <div
                style={{
                  fontSize: "10px",
                }}
                className={css.uploadSelector}
              >
                <label
                  className={imagesPerRequest === 5 ? css.selected : ""}
                  htmlFor="uplFive"
                >
                  Upload 5 photos
                  <br />
                  at once - <span className="fw-bold">Faster</span>
                </label>
                <input
                  onClick={uploadLimitChange}
                  type="radio"
                  value="five"
                  id="uplFive"
                  disabled={isUploading}
                />
                <label
                  className={imagesPerRequest !== 5 ? css.selected : ""}
                  htmlFor="uplOne"
                >
                  Upload 1 photo at
                  <br />
                  once - <span className="fw-bold">Sequential</span>
                </label>
                <input
                  onClick={uploadLimitChange}
                  type="radio"
                  value="one"
                  id="uplOne"
                  disabled={isUploading}
                />
              </div>
            </div>
          )} */}
        </Modal.Header>
        <Modal.Body className="px-2">
          <Spinner loading={loading || settingLoader} />
          <div className="row align-items-center gx-0 gap-3 gap-md-0">
            <div className="col-12 col-md-7 d-flex">
              <div className={css.iconHolder}>
                <img src="/assets/icons/jpg-icon.svg" alt="jpg-icon" />
              </div>
              <ProgressCard
                progressPercentage={
                  (successFullySentToBackend / allUploadedImages.length) *
                    100 || 0
                }
                thumb={true}
                leftText={`${successFullySentToBackend}/${
                  allUploadedImages.length
                } Photos${
                  hasUploadedAllImages
                    ? ", " +
                      successfullyUploaded.length +
                      " success, " +
                      duplicateImages.length +
                      " duplicates, " +
                      imagesWithError.length +
                      " errors."
                    : ""
                }`}
                rightText={bytesToSize(totalImagesSize)}
                fullWidth={true}
              />
            </div>
            <div className="col-12 col-md-5 d-flex gx-0 gap-2">
              <div className="col">
                <h5 className="font-bold font-15">Choose Folder</h5>
                <Dropdown onSelect={setFolderPrefix} className="createDrop">
                  <Dropdown.Toggle
                    ref={dropRef}
                    variant="light"
                    id="dropdown-basic"
                    disabled={isUploading || (guestFolderOnly && !isAdmin)}
                  >
                    <span className="font-bold font-15 bright-blue-text">
                      {folderPrefix}
                    </span>
                    <span>
                      <img
                        width="15"
                        src="/assets/images/icons/arrow-down.png"
                        alt="down icon"
                      />
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="p-0 rounded-2">
                    <AddFolder
                      setAllFolder={setAllFolder}
                      allFolder={_allFolders}
                      setFolderPrefix={setFolderPrefix}
                    />
                    <Dropdown.Item eventKey={STATIC_FOLDERS.ALL_PHOTOS}>
                      {folderPrefix === STATIC_FOLDERS.ALL_PHOTOS ? (
                        <p className="m-0 bright-blue-text">
                          {STATIC_FOLDERS.ALL_PHOTOS}
                        </p>
                      ) : (
                        <p className="m-0 gray-text">
                          {STATIC_FOLDERS.ALL_PHOTOS}
                        </p>
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={STATIC_FOLDERS.HIGHLIGHTS}>
                      {folderPrefix === STATIC_FOLDERS.HIGHLIGHTS ? (
                        <p className="m-0 bright-blue-text">
                          {STATIC_FOLDERS.HIGHLIGHTS}
                        </p>
                      ) : (
                        <p className="m-0 gray-text">
                          {STATIC_FOLDERS.HIGHLIGHTS}
                        </p>
                      )}
                    </Dropdown.Item>
                    {_allFolders.map((folder, index) => {
                      return (
                        <Dropdown.Item
                          eventKey={folder?.folderPrefix || "no-name-" + index}
                          key={index}
                        >
                          {folderPrefix === folder.folderPrefix ? (
                            <p className="m-0 bright-blue-text">
                              {folder.folderPrefix || "-"}
                            </p>
                          ) : (
                            <p className="m-0 gray-text">
                              {folder.folderPrefix || "-"}
                            </p>
                          )}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {allUploadedImages.length > 0 && (
                <div className="align-self-end">
                  <Dropdown
                    onSelect={(e) => {
                      handleSortBy(e, allUploadedImages);
                    }}
                    className={css.dropdown}
                    aria-disabled={isUploading}
                  >
                    <Dropdown.Toggle
                      variant="light"
                      title="Click to change images order"
                      className={css["sort-icon"]}
                      disabled={isUploading}
                    >
                      <img
                        src={`${SORT_STRATEGIES[sortByRef.current]}-active.png`}
                        alt="sort icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="p-0 rounded-2">
                      <Dropdown.Item
                        eventKey={SORT_TYPES.ASC}
                        title="Sort A to Z"
                        active={sortByRef.current === SORT_TYPES.ASC}
                      >
                        <img
                          src={`${SORT_STRATEGIES[SORT_TYPES.ASC]}.png`}
                          alt="A to Z"
                        />
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey={SORT_TYPES.DESC}
                        title="Sort Z to A"
                        active={sortByRef.current === SORT_TYPES.DESC}
                      >
                        <img
                          src={`${SORT_STRATEGIES[SORT_TYPES.DESC]}.png`}
                          alt="Z to A"
                        />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
          </div>

          {/* <div style={{ height: "35vh" }} className="row"> */}
          <div
            style={{ height: "250px", background: "#ffffff" }}
            className="col-md-12 mt-3 card-height cards-outer"
          >
            {isUploading || allUploadedImages.length > 0 ? (
              <div className="d-flex">
                {/* <div
                  style={{
                    fontSize: "12px",
                    background: "#f3f7f9",
                    color: "#3e6588",
                    width: "8.5ch",
                  }}
                >
                  <p
                    className="m-0 mt-3 position-absolute top-0"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    Displayed
                    <br />
                    LAST →
                  </p>
                  <p
                    className="m-0 mb-3 position-absolute bottom-0"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    Displayed
                    <br />
                    FIRST →
                  </p>
                </div> */}

                <AutoSizer>
                  {({ height, width }) => {
                    return (
                      // <div className="uploadingCard">
                      <List
                        className={css.listStyle}
                        height={height}
                        width={width}
                        rowHeight={45}
                        rowCount={allUploadedImages.length}
                        rowRenderer={({ style, key, index }) => {
                          let image = allUploadedImages[index];

                          return (
                            <UploadingCard
                              key={key}
                              style={style}
                              image={image}
                              isUploading={isUploading}
                            />
                          );
                        }}
                      />
                      // </div>
                    );
                  }}
                </AutoSizer>
              </div>
            ) : (
              <Dropzone
                accept={{
                  "image/png": [".png"],
                  "image/jpeg": [".jpeg"],
                  "image/jpg": [".jpg"],
                  "image/heic": [".heic"],
                  "image/heif": [".heif"],
                }}
                multiple={true}
                noClick={true}
                onDrop={onDrop}
              >
                {({ getRootProps, getInputProps, open }) => (
                  <label
                    className="dropableCard"
                    htmlFor="fileUploader"
                    {...getRootProps()}
                  >
                    <input
                      {...getInputProps({
                        className: "d-none",
                        id: "fileUploader",
                        // webkitdirectory: "",
                        // directory: "",
                      })}
                    />
                    <span className="bold-font font-15">Drag photos here</span>
                    <div className="mt-2" />
                    <span className="bold-font font-15">or</span>
                    <div className="mt-2" />
                    <SmallRoundedButton title="Browse" onClick={open} />
                  </label>
                )}
              </Dropzone>
            )}
          </div>
        </Modal.Body>

        {allUploadedImages.length > 0 && (
          <Modal.Footer className="px-1">
            <div className={css.footerContainer}>
              {isUploading ? (
                <>
                  <span className="warning-message">
                    *Please do not quit the application. Images are still
                    Uploading.
                  </span>
                </>
              ) : hasUploadedAllImages ? (
                <>
                  <span className="uploadComplete">Completed!</span>
                  {imagesWithError.length > 0 && (
                    <PrimarySmallBtn
                      title="Retry Failed Uploads"
                      onClick={handleFailedUploads}
                    />
                  )}
                  <Dropzone
                    accept={{
                      "image/png": [".png"],
                      "image/jpeg": [".jpeg"],
                      "image/jpg": [".jpg"],
                      "image/heic": [".heic"],
                      "image/heif": [".heif"],
                    }}
                    multiple={true}
                    noClick={true}
                    onDrop={onDrop}
                  >
                    {({ getRootProps, getInputProps, open }) => (
                      <label {...getRootProps()} htmlFor="uploadMore">
                        <input
                          {...getInputProps()}
                          type="file"
                          id="uploadMore"
                          multiple="multiple"
                        />
                        <SecondarySmallBtn title="Upload More" onClick={open} />
                      </label>
                    )}
                  </Dropzone>
                </>
              ) : (
                <DefaultButton
                  title="Start Upload"
                  className="fw-bold"
                  onClick={() => {
                    storageCon
                      ? handleStorageFull()
                      : startUploading(undefined, dropRef);
                  }}
                />
              )}
            </div>
          </Modal.Footer>
        )}
        <ConfirmationModal {...confirmationModal} />
        <StorageFullModal
          show={subscriptionModal.storage}
          onHide={() => setSubscriptionModal((p) => ({ ...p, storage: false }))}
          onConfirm={() =>
            setSubscriptionModal({
              storage: false,
              subscription: true,
            })
          }
          type="photos"
          exceed={oneXCondition ? "1x" : "2x"}
        />
        {subscriptionModal.subscription && (
          <SubscriptionModal
            title="UPGRADE YOUR PLAN"
            isOpen={true}
            onClose={() =>
              setSubscriptionModal((p) => ({ ...p, subscription: false }))
            }
          />
        )}
      </Modal>
    </>
  );
};

export const UploadingCard = ({ image, isUploading, style }) => {
  const imageHasError =
    image.status === videoStatus.LIMIT_EXCEED ||
    image.status === imagesStatus.DUPLICATE ||
    image.status === imagesStatus.ERROR;

  const isChecked =
    image.status === videoStatus.LIMIT_EXCEED ||
    image.status === imagesStatus.SUCCESS ||
    image.status === imagesStatus.DUPLICATE ||
    image.status === imagesStatus.ERROR;

  return (
    <div className="d-flex align-items-center w-100 ps-4" style={style}>
      <div className="flex-grow-1 thin-progress-bar">
        <ProgressCard
          variant={imageHasError ? "danger" : "default"}
          leftText={image.name}
          type="files"
          progressPercentage={getProgress(image)}
          rightText={bytesToSize(image.size)}
        />
      </div>
      <div className="uploadingStatus">
        <span className={imageHasError ? "error" : ""}>
          {getImageStatus(image?.status, isUploading)}
        </span>
        {isChecked ? (
          <span
            className={`checked ${imageHasError ? "checked-error" : ""}`}
          ></span>
        ) : (
          <span className="unchecked"></span>
        )}
      </div>
    </div>
  );
};

export default UploadFromComputer;

function getProgress(image) {
  if (
    image.status === imagesStatus.SUCCESS ||
    image.status === imagesStatus.DUPLICATE ||
    image.status === imagesStatus.ERROR ||
    image.status === videoStatus.LIMIT_EXCEED
  ) {
    return 100;
  } else {
    return image?.uploadProgress || 0;
  }
}

function getImageStatus(status, isUploading) {
  if (
    status === imagesStatus.DUPLICATE ||
    status === imagesStatus.ERROR ||
    status === imagesStatus.SUCCESS ||
    status === videoStatus.LIMIT_EXCEED
  ) {
    return status;
  } else if (isUploading) {
    return "Uploading";
  } else {
    return imagesStatus.READY;
  }
}
