import ENV from "./env";
import { PAID_FEATURES } from "constants/plans";
import { getLocalStorageUser } from "./localstorage";
import { getUserAnalyticsFromLS } from "./localstorage";

export const getPhoneNumber = () => {
  return ENV.WL_CONTACT_WA;
};

export const getWhatsappURL = () => {
  return `https://wa.me/${getPhoneNumber()}`;
};

export const generateWhatsappSubscriptionMessage = (plan, id) => {
  const userId = getLocalStorageUser()?._id || id || "";

  const template = `Hi, I would like to purchase the "${plan}".

My user id is "${userId}"`;

  return encodeURIComponent(template);
};

export const getWhatsappMessage = (slackMessage) => {
  let wtsappURL = getWhatsappURL();
  wtsappURL += `?text=${slackMessage}&app_absent=0`;

  return wtsappURL;
};

export const slackNoAuthMessage = (
  plan,
  tenure,
  user,
  userType,
  frontendType
) => {
  let text = `Hi, I would like to purchase the ${plan} plan.`;

  if (user) {
    text += `\n\n*Name*: ${user?.name}`;
    text += `\n*Email*: ${user?.email}`;
    text += `\n*Country Code*: ${user?.cc}`;
    text += `\n*Phone Number*: ${user?.phone}`;
  }

  if (userType) {
    text += `\n\n*Type*: ${userType}`;
  }

  if (tenure) {
    text += `\n\n*Tenure*: ${tenure === "monthly" ? "quarterly" : tenure}`;
  }

  text += `\n\n*Frontend Type*: ${frontendType || "web"}`;

  return encodeURIComponent(text);
};

export const slackMessage = (
  plan,
  tenure,
  frontendType,
  change = false,
  groupId = ""
) => {
  const user = {
    ...getLocalStorageUser(),
    ...getUserAnalyticsFromLS()?.userAnalytics,
  };

  const userId = getLocalStorageUser()?._id || user.userId;

  const isFeature = !!PAID_FEATURES[plan];

  let text = "";
  if (plan) {
    if (change) {
      text += `Hi, I would like to change my plan to the ${plan} plan. My userId is ${userId}.`;
    } else if (isFeature) {
      text = `Hi, I would like to activate the ${plan} feature in groupId ${groupId}. My userId is ${userId}.`;
    } else {
      text = `Hi, I would like to purchase the ${plan} plan. My userId is ${userId}.`;
    }
  } else {
    text = `Hi, I would like to purchase a plan. My userId is ${userId}.`;
  }

  if (user) {
    text += `\n\n*Name*: ${user?.name}`;
    text += `\n*Last Name*: ${user?.lastName}`;
    text += `\n*User Type*: ${user?.userType}`;
    text += `\n*Country Code*: ${
      user?.countryCode || user?.secondaryPhoneCountryCode
    }`;
    text += `\n*Phone Number*: ${user?.phoneNumber || user?.secondaryPhone}`;
    text += `\n*Email*: ${user?.email || user?.secondaryEmail}`;

    if (!isFeature) {
      text += `\n\n*Photo Uploads*: ${user?.photoUploads}`;
      text += `\n*Photo Deletes*: ${user?.photoDeletes}`;
      text += `\n*High Res Uploads*: ${user?.highResPhotoUploads}`;
      text += `\n*High Res Deletes*: ${user?.highResPhotoDeletes}`;
      text += `\n*Videos Uploads*: ${user?.videoUploads}`;
      text += `\n*Videos Deletes*: ${user?.videoDeletes}`;
      text += `\n*Groups*: ${user?.groups}`;
    }
  }

  if (tenure && !isFeature) {
    text += `\n\n*Tenure*: ${tenure === "monthly" ? "quarterly" : tenure}`;
  }

  text += `\n\n*Frontend Type*: ${frontendType || "web"}`;

  return encodeURIComponent(text);
};

export const storageFullSlackMessage = (uploadCount) => {
  const user = getLocalStorageUser();
  const userId = user?._id || "";
  const {
    uploadLimit,
    userAnalytics: {
      photoDeletes,
      photoUploads,
      highResPhotoUploads,
      highResPhotoDeletes,
    },
  } = getUserAnalyticsFromLS();

  let text = `Hi, I have exceeded my storage quota. My userId is ${userId}.`;

  if (user) {
    text += `\n\n*Name*: ${user?.name}`;
    text += `\n*Last Name*: ${user?.lastName}`;
    text += `\n*User Type*: ${user?.userType}`;
    text += `\n*Country Code*: ${
      user?.countryCode || user?.secondaryPhoneCountryCode
    }`;
    text += `\n*Phone Number*: ${user?.phoneNumber || user?.secondaryPhone}`;
    text += `\n*Email*: ${user?.email || user?.secondaryEmail}`;
  }

  text += `\n\n*Storage limit*: ${uploadLimit}`;
  text += `\n*Storge Utilization*: ${Math.round(
    ((photoUploads -
      photoDeletes +
      (highResPhotoUploads - highResPhotoDeletes) * 2.5) /
      uploadLimit) *
      100
  )}%`;
  if (uploadCount) text += `\nUser tried to upload ${uploadCount} photos.`;

  return encodeURIComponent(text);
};
