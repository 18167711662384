import { takeLatest, put, call, delay } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
  GET_GROUP_DESIGNA_AND_SETTINGS,
  PUT_GROUP_GENERAL_SETTINGS,
  PATCH_GROUP_PRIVACY_SETTINGS,
  PATCH_GROUP_PARTICIPANTS_MAKE_ADMIN,
  PATCH_LEAVE_GROUP,
  POST_GROUP_DESIGN_AND_SETTING,
  GET_BUSINESS_BRANDING,
  POST_BUSINESS_BRANDING,
  POST_BUSINESS_LOGO,
  GET_USER_DETAILS,
  SEND_OTP_TO_UPDATE_PHONE_OR_EMAIL,
  VERIFY_OTP_TO_UPDATE_PHONE_OR_EMAIL,
  SET_USER_PROFILE,
  DELETE_GROUP_ICON,
  REMOVE_PARTICIPANT,
  GET_FAVOURITE_IMAGES,
  GET_USER_ANALYTICS,
  SEND_USER_CLICK_ANALYTICS,
  GET_GROUP_ADMIN_TOKEN,
  POST_BUSINESS_WATERMARK,
  POST_WATERMARK_OPTIONS,
  DELETE_WATERMARK,
  GET_BRANCH_URL,
  PUT_COVER,
  PUT_PHOTOGRAPHER_PORTFOLIO,
  DELETE_MY_ACCOUNT,
  DELETE_PORTFOLIO_IMAGE,
  DELETE_RENAME_PORTFOLIO_FOLDER,
  GET_USER_WALLET,
  ADD_CREDITS_TO_WALLET,
  UPLOAD_ALBUM_LOGO,
  GET_ALBUM_DETAILS,
  DELETE_ALBUM_IMAGE,
  CHANGE_ALBUM_IMAGE_ORDER,
  UPDATE_ALBUM_COLOR,
  UPDATE_ALBUM_PIN,
  TOGGLE_BLOCK_PARTICIPANT,
} from "./saga-actions";
import { AXIOS, AXIOS2, AXIOS_ANALYTICS } from "../../utils/setup/axios";
import {
  setGroupSettingsAndOtherSettings,
  setGroupDesignaAndSettings,
  setGroupParticipantsSettings,
  handleGroupGeneralSettingsChange,
  setGroupPrivacySettings,
  setPostGroupDesignaAndSettings,
  setBusinessBranding,
  setSettingLoader,
  setSaveLoader,
  setPutBusinessLogo,
  setLeaveGroupStatus,
  setUserProfileData,
  setSendOtpToUpdatePhoneOrEmail,
  setVerifyOtpToUpdatePhoneOrEmail,
  setDeleteGroupIcon,
  removeParticipantFromGroup,
  setUserAnalytics,
  setGroupAdminToken,
  setPutBusinessWatermark,
  removeWatermark,
  setBranchUrl,
  setUserWallet,
  setCreditsAddResponse,
  setDigitalAlbum,
  setError,
  setGroupSettingsLoader,
} from "../slices/settings";
import { updateUser } from "redux-store/slices/user";
import { clientFavourites } from "redux-store/slices/settings";
import { retryAPIDelay } from "./folders";
import {
  addUserAnalyticsToLocalStorage,
  getLocalStorageUser,
  lsProxy,
} from "utils/helpers/localstorage";
import axios from "axios";
import {
  photographerPortfolio,
  setCoverImage,
  deleteOrRenameFolder,
  deleteImagesPortFolio,
} from "redux-store/slices/folderImages";
import { getBranchKey } from "views/download/helpers";
import { setServices } from "redux-store/slices/portfolio";
import ENV from "utils/helpers/env";

async function getGroupDetailsAndOtherSettings(data) {
  const { groupId, sendAll, sendBlockedUsers } = data || {};

  return AXIOS.get(`/api/app/group/web-details/${groupId}`, {
    params: { sendAll, sendBlockedUsers },
  });
}

async function getGroupDesignaAndSettings(data) {
  return AXIOS.get(`/api/app/group/web-settings/${data.groupId}`);
}

async function removeParticipant(data) {
  const { groupId, participantId } = data.payload || {};
  return AXIOS.patch(`/api/app/group/remove-participant/${groupId}`, {
    participantId,
  });
}

async function favoriteImages(data) {
  const { groupID, isViewer, page, limit } = data || {};
  return AXIOS.get(`/api/app/analytics/get-favourites/${groupID}`, {
    params: { isViewer, page, limit },
  });
}

async function postGroupDesignAndSetting(data) {
  const { groupId, source } = data?.payload || {};
  let postData = { groupId };

  if (source === "design") {
    const { colorMode, font, grid, padding, photoSize, template } =
      data.payload?.design || {};
    postData = {
      ...postData,
      colorMode,
      font,
      grid,
      padding,
      photoSize,
      template,
    };
  } else if (source === "download") {
    postData = {
      ...postData,
      allowDownload: data.payload?.download?.allowDownload,
      bulkDownload: data.payload?.download?.bulkDownload,
      originalDownload: data.payload?.download?.originalDownload,
    };
  } else if (source === "privacy") {
    postData = {
      ...postData,
      anonymousAccess: data.payload?.anonymousAccess,
    };
  } else if (source === "branding") {
    postData = {
      ...postData,
      ...data.payload,
    };
  } else if (source === "bundles") {
    postData = {
      ...postData,
      ...data.payload,
    };
  }

  return AXIOS.post(`/api/app/group/web-settings`, postData);
}

async function putGroupGeneralSettings(data) {
  const {
    groupID,
    imageChanged,
    groupName,
    iconFocalPoint,
    groupCover,
    sortStrategy,
    forMobile,
  } = data.payload || {};

  const formData = new FormData();
  formData.append("name", groupName);
  formData.append("iconFocalPoint", iconFocalPoint);
  formData.append("icon", groupCover);
  formData.append("isIconChange", imageChanged);
  formData.append("sortStrategy", sortStrategy);
  if (forMobile !== undefined) formData.append("forMobile", forMobile);

  return AXIOS2.put(`/api/app/group/change-icon/${groupID}`, formData);
}

async function patchGroupPrivacySettings(data) {
  const {
    groupID,
    isPrivate,
    canAnyoneUploadPhotos,
    canAnyoneChangeNameIcon,
    uploadParticipants,
    newJoineShowPreviousPhotos,
    anyOneJoinWithLink,
    isDeletedFolderHidden,
    guestFolderOnly,
    realCheck,
  } = data.payload || {};

  return AXIOS.patch(`/api/app/group/edit-settings/${groupID}`, {
    isPrivate,
    canAnyoneUploadPhotos,
    canAnyoneChangeNameIcon,
    newJoineShowPreviousPhotos,
    isDeletedFolderHidden,
    anyOneJoinWithLink,
    guestFolderOnly,
    realCheck,
    ...(!canAnyoneUploadPhotos && { uploadParticipants }),
  });
}

async function patchGroupParticipantsMakeAdmin(data) {
  const { groupId, isAdmin, participantId } = data.payload || {};
  if (isAdmin === true) {
    return AXIOS.patch(`/api/app/group/make-admin/${groupId}`, {
      participantId,
    });
  } else {
    return AXIOS.patch(`/api/app/group/remove-admin/${groupId}`, {
      participantId,
    });
  }
}

async function patchLeaveGroup(data) {
  const { groupId } = data || {};
  return AXIOS.patch(`/api/app/group/leave/${groupId}`);
}

async function getBusinessBranding() {
  return AXIOS.get(`/api/app/user/my-photographer-profile`);
}

async function postBusinessBranding(data) {
  const action = data?.payload || {};
  delete action["user"];

  Object.keys(action).forEach((key) => {
    if (!action[key] && action[key] !== false) {
      delete action[key];
    }
  });

  return AXIOS.post(`/api/app/user/set-photographer-profile`, {
    ...(action || {}),
  });
}

async function putBusinessLogo(data) {
  const { groupCover } = data.payload || {};

  const formData = new FormData();
  formData.append("logo", groupCover);
  return AXIOS.put(`/api/app/user/upload-photographer-logo`, formData);
}

async function putBusinessWatermark(data) {
  const { watermark } = data.payload || {};

  const formData = new FormData();
  formData.append("watermark", watermark);
  return AXIOS.put(`/api/app/user/upload-photographer-watermark`, formData);
}

async function postWatermarkOptions(data) {
  const props = data.payload || {};
  return AXIOS.post(`/api/app/user/set-watermark-options`, props);
}

async function postDeleteWatermark() {
  return AXIOS.get(`/api/app/user/delete-watermark`);
}

async function deleteGroupIcon(groupId) {
  return AXIOS.patch(`api/app/group/delete-icon/`, { groupId });
}

async function getBranchUrl(payload) {
  const { title, uCode, icon } = payload;

  const { key, name } = getBranchKey();

  const finalLink = `${
    ENV.WL_HOST || window.location.origin
  }/auth/login?uCode=${uCode}`;

  return axios.post("https://api2.branch.io/v1/url", {
    branch_key: key,
    channel: "web",
    feature: "onboarding",
    campaign: "Web_Share_Link",
    stage: "new user",
    tags: "tag",
    data: {
      $canonical_identifier: "content/123",
      $og_title: title,
      $og_description: name,
      $og_image_url: icon,
      $desktop_url: finalLink,
      groupCode: uCode,
    },
  });
}

function* getBranchUrlGenerator(action) {
  try {
    const response = yield call(getBranchUrl, action?.payload);
    yield put(
      setBranchUrl({ ...response.data, groupCode: action?.payload?.uCode })
    );
  } catch (e) {
    //
  }
}

function* getGroupDetailsAndOtherSettingsGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    yield put(setGroupSettingsLoader(true));

    const response = yield call(
      getGroupDetailsAndOtherSettings,
      action.payload
    );
    yield put(setGroupSettingsAndOtherSettings(response.data));
    yield put(
      setGroupDesignaAndSettings({
        data: response.data?.data?.webSettings,
        status: response.data?.status,
      })
    );
  } catch {
    try {
      yield delay(retryAPIDelay);
      const response = yield call(
        getGroupDetailsAndOtherSettings,
        action.payload
      );
      yield put(setGroupSettingsAndOtherSettings(response.data));
      yield put(
        setGroupDesignaAndSettings({
          data: response.data?.data?.webSettings,
          status: response.data?.status,
        })
      );
    } catch (e) {
      // 400: not a group participant
      if (e.response.status === 400 || e.response.status === 403) {
        toast.error(e.response.data?.message);
        setTimeout(() => {
          window.location.replace("/groups");
        }, 2000);
      }
    }
  } finally {
    yield put(setSettingLoader(false));
    yield put(setGroupSettingsLoader(false));
  }
}

function* getGroupDesignaAndSettingsGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(getGroupDesignaAndSettings, action);
    yield put(setGroupDesignaAndSettings(response.data));
  } catch {
    try {
      yield delay(retryAPIDelay);
      const response = yield call(getGroupDesignaAndSettings, action);
      yield put(setGroupDesignaAndSettings(response.data));
    } catch (e) {
      // 403: not a group participant
      // 400: invalid group id
      if (e.response.status === 400) {
        toast.error(e.response.data?.message);
        setTimeout(() => {
          window.location.replace("/groups");
        }, 2000);
      }
    }
  } finally {
    yield put(setSettingLoader(false));
  }
}

function* postGroupDesignAndSettingGenerator(action) {
  try {
    yield put(setSaveLoader(true));

    const response = yield call(postGroupDesignAndSetting, action);
    if (response.data.status === 200) {
      toast.success(response.data.message);
    }
    yield put(setPostGroupDesignaAndSettings(response.data));
    yield put(setGroupDesignaAndSettings(response.data));
  } catch (e) {
    if ([400, 403, 500].includes(e.response.status)) {
      toast.error(e.response.data?.message);
    }
  } finally {
    yield put(setSaveLoader(false));
  }
}

function* putGroupGeneralSettingsGenerator(action) {
  try {
    const response = yield call(putGroupGeneralSettings, action);

    if (response.data.status === 201) {
      toast.success(response?.data?.message);
    }
    yield put(handleGroupGeneralSettingsChange(response.data));
  } catch (e) {
    yield put(setSaveLoader(false));
    if (e.response.status === 400) {
      toast.error(e.response.data?.message);
    }
  }
}

function* patchGroupPrivacySettingsGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(patchGroupPrivacySettings, action);
    if (response.data.status === 201) {
      toast.success(response.data.message);
    }
    yield put(setGroupPrivacySettings(response.data));
  } catch (e) {
    if (e.response.status === 400) {
      toast.error(e.response.data?.message);
    }
  } finally {
    yield put(setSaveLoader(false));
  }
}

function* patchGroupParticipantsMakeAdminGenerator(action) {
  try {
    const response = yield call(patchGroupParticipantsMakeAdmin, action);
    if (response.data.status === 201 || response.data.status === 200) {
      toast.success(response.data.message);
    }
    yield put(
      setGroupParticipantsSettings({
        data: response?.data,
        id: action.payload?.participantId,
      })
    );
  } catch (e) {
    yield put(setSaveLoader(false));
    if (e.response.status === 400 || e.response.status === 403) {
      toast.error(e.response.data?.message);
    }
  }
}

function* patchLeaveGroupGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(patchLeaveGroup, action);
    yield put(setLeaveGroupStatus(response.data));
  } catch (e) {
    yield put(setSaveLoader(false));
    if (e.response.status === 500) {
      toast.error(e.response.data?.message);
    }
  }
}

function* getBusinessBrandingGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(getBusinessBranding, action);
    yield put(setBusinessBranding(response.data));
  } catch (e) {
    // yield put(setSettingLoader(false));
  } finally {
    yield put(setSettingLoader(false));
  }
}

function* postBusinessBrandingGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(postBusinessBranding, action);
    if (response.data?.status === 200) {
      toast.success(response.data.message);
    }
    yield put(setError(""));
  } catch (e) {
    if (e.response?.data?.status === 400) {
      toast.error(e.response?.data?.message);
    }
    yield put(setError(e.response.data?.data || e.response.data?.error));
  } finally {
    yield put(setSaveLoader(false));
  }
}

function* putBusinessLogoGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(putBusinessLogo, action);
    if (response.data.status === 200 || response.data.status === 201) {
      toast.success(response.data.message);
    }
    yield put(setPutBusinessLogo(response.data));
  } catch (e) {
    if (e.response.status === 400) {
      toast.error(e.response.data?.message);
    }
  } finally {
    yield put(setSaveLoader(false));
  }
}

function* putBusinessWatermarkGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(putBusinessWatermark, action);
    if (response.data.status === 200 || response.data.status === 201) {
      toast.success(response.data.message);
    }
    yield put(setPutBusinessWatermark(response.data));
  } catch (e) {
    if (e.response.status === 400) {
      toast.error(e.response.data?.message);
    } else if (e.response.status === 500) {
      toast.error(
        "Watermark size exceeds permitted dimension limit. Please upload a smaller image."
      );
    }
  } finally {
    yield put(setSaveLoader(false));
  }
}

function* postDeleteWatermarkGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(postDeleteWatermark, action);
    if (response.data.status === 200 || response.data.status === 201) {
      toast.success(response.data.message);
    }
    yield put(removeWatermark());
  } catch (e) {
    if (e.response.status === 400) {
      toast.error(e.response.data?.message);
    }
  } finally {
    yield put(setSaveLoader(false));
  }
}

function* postWatermarkOptionsGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(postWatermarkOptions, action);
    if (response.data.status === 200 || response.data.status === 201) {
      toast.success(response.data.message);
    }
    yield put(
      setBusinessBranding({ ...response.data, data: response.data?.data?.pg })
    );
  } catch (e) {
    if (e.response.status === 400) {
      toast.error(e.response.data?.message);
    }
  } finally {
    yield put(setSaveLoader(false));
  }
}

async function getUserDetails() {
  return AXIOS.get("/api/app/user/my-profile");
}

function* getUserDetailsGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(getUserDetails, action);
    yield put(setUserProfileData(response?.data));
  } catch (e) {
    // yield put(setSettingLoader(false));
  } finally {
    yield put(setSettingLoader(false));
  }
}

async function sendOtpToUpdatePhoneOrEmail(action) {
  const { phoneNumber, type, email } = action?.payload || {};
  let data = {};

  if (type === "Phone") {
    data = {
      phoneNumber,
      loginType: "PHONE",
      countryCode: "+91",
    };
  } else if (type === "Email") {
    data = {
      email,
      loginType: "EMAIL",
    };
  }

  return AXIOS.post(`/api/app/user/send-otp`, {
    ...data,
  });
}

function* sendOtpToUpdatePhoneOrEmailGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(sendOtpToUpdatePhoneOrEmail, action);

    if (response.status === 200) {
      toast.success(response?.data?.message);
    }
    yield put(setSendOtpToUpdatePhoneOrEmail(response?.data));
  } catch (e) {
    yield put(setSettingLoader(false));
    toast.error(e.response.data?.message);
  }
}

async function verifyOtpToUpdatePhoneOrEmail(action) {
  const { otp, type, email, phoneNumber, countryCode } = action?.payload || {};
  let data = {};

  if (type === "Phone") {
    data = {
      otp,
      phoneNumber,
      countryCode,
    };
  } else if (type === "Email") {
    data = {
      otp,
      email,
    };
  }

  return AXIOS.post(`/api/app/user/verify-otp-changed`, {
    ...data,
  });
}

function* verifyOtpToUpdatePhoneOrEmailGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(verifyOtpToUpdatePhoneOrEmail, action);
    if (response.status === 200) {
      toast.success("OTP Verified");
    }
    yield put(setVerifyOtpToUpdatePhoneOrEmail(response?.data));
  } catch (e) {
    yield put(setSettingLoader(false));
    toast.error(e?.response?.data?.message);
  }
}

async function setUserProfile(action) {
  let token = lsProxy.getItem("token");
  // eslint-disable-next-line no-unused-vars
  const { src, ...data } = action?.payload || {};
  const { name, lastName, tocAccepted } = getLocalStorageUser();

  let payload = {
    name,
    lastName,
    tocAccepted,
    ...data,
  };

  if (!token) {
    // eslint-disable-next-line no-unused-vars
    const { src, token: authToken, ...actionPayload } = action?.payload || {};
    token = "Bearer " + authToken;
    payload = actionPayload;
  }

  return AXIOS.post(
    `/api/app/user/set-profile`,
    {
      ...(payload || {}),
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
}

function* setUserProfileGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(setUserProfile, action);
    if (response.status === 200) {
      if (action?.payload?.src === "downOQ")
        toast.success("Downloading image. Preference updated successfully");
      else toast.success(response?.data?.message || "Profile Updated");

      if (action.payload.src !== "register") {
        yield put(updateUser(response?.data?.data?.user));
        yield put(setUserProfileData(response?.data));
      }
    }
    if (response.status === 201) {
      toast.success(response?.data?.message || "Sent a verification code");
    }

    yield put(setSendOtpToUpdatePhoneOrEmail(response?.data));
  } catch (e) {
    toast.error(
      e?.response?.data?.error?.[0] ||
        e?.response?.data?.message ||
        "Something went wrong."
    );
  } finally {
    yield put(setSettingLoader(false));
  }
}

function* getFavoriteGenerator(action) {
  const { groupID } = action.payload || {};

  try {
    yield put(setSettingLoader(true));
    const response = yield call(favoriteImages, action.payload);
    if (response.status === 200) {
      yield put(clientFavourites({ data: response?.data, groupID }));
    }
  } catch (e) {
    yield put(setSettingLoader(false));
    // toast.error(e?.response?.data?.message);
  }
  yield put(setSettingLoader(false));
}

function* deleteGroupIconGenerator(data) {
  try {
    const response = yield call(deleteGroupIcon, data.payload.groupId);
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      toast.success(response?.data?.message);
    }
    yield put(setDeleteGroupIcon(data));
  } catch (e) {
    toast.error(e?.response?.data?.message);
  }
}

function* removeParticipantGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(removeParticipant, action);
    if (response.status === 200) {
      toast.success(response?.data?.message || "Removed");
    }
    yield put(
      removeParticipantFromGroup({
        ...(response?.data || {}),
        groupId: action?.payload?.groupId,
        participantId: action?.payload?.participantId,
      })
    );
  } catch (e) {
    yield put(setSettingLoader(false));
    toast.error(e?.response?.data?.message);
  }
  yield put(setSettingLoader(false));
}

async function getUserAnalytics() {
  return AXIOS.get("/api/app/analytics/userAnalytics");
}

async function sendUserClickAnalytics(data) {
  return AXIOS_ANALYTICS.post(
    "/api/app/analytics/send-user-click-analytics",
    data
  );
}

function* getUserAnalyticsGenerator() {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(getUserAnalytics);
    if (response.status === 200) {
      addUserAnalyticsToLocalStorage(response?.data?.data);
      yield put(setUserAnalytics(response?.data));
    }
  } catch (e) {
    // yield put(setSettingLoader(false));
  } finally {
    yield put(setSettingLoader(false));
  }
}

function* sendUserClickAnalyticsGenerator(action) {
  try {
    yield call(sendUserClickAnalytics(action.payload));
  } catch (_) {
    //Nothing to do for now
  }
}

async function getGroupAdminToken(action) {
  const { groupCode } = action;
  return AXIOS.get(
    `/api/app/group/generate-token-for-admin?groupCode=${groupCode}`
  );
}

function* getGroupAdminTokenGenerator(action) {
  try {
    const response = yield call(getGroupAdminToken, action);
    if (response.status === 200) {
      yield put(setGroupAdminToken(response?.data?.data));
    }
  } catch (e) {
    //
  }
}

async function putCoverImg(action) {
  const { cover } = action?.payload || {};
  const formData = new FormData();
  formData.append("image", cover);
  return AXIOS.put("/api/app/user/upload-photographer-cover-image", formData);
}

function* putCoverImgGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(putCoverImg, action);
    if (response.status === 200 || response.status === 201) {
      toast.success(response?.data?.message || "Cover Image Uploaded");
    }
    yield put(setCoverImage(response?.data?.data));
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSettingLoader(false));
  }
}

async function putPhotographerPortfolio(data) {
  return AXIOS.put("/api/app/user/set-photographer-portfolio", data);
}

function* putPhotographerPortfolioGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const { data, quiet } = action?.payload || {};
    const response = yield call(putPhotographerPortfolio, data);
    if (response.status === 200 && !quiet) {
      toast.success(response?.data?.message || "Portfolio Updated");
    }
    yield put(photographerPortfolio(response?.data?.data));
    yield put(setServices(response.data.data?.photographer?.services || []));
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSaveLoader(false));
  }
}

async function deleteMyAccount() {
  return AXIOS.get("/api/app/user/delete-account");
}

function* deletAccountGenerator() {
  yield put(setSettingLoader(true));
  try {
    const response = yield call(deleteMyAccount);
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message || "Account Deleted");

      // logout after 2 seconds
      setTimeout(() => {
        lsProxy.clear();
        window.location.replace("/auth/login");
      }, 2000);
    }
  } catch (e) {
    toast.error(e?.response?.data?.message);
    yield put(setError(e?.response?.data?.message));
  } finally {
    yield put(setSettingLoader(false));
  }
}

async function deletePortfolioImages(data) {
  const { urls } = data || {};
  return AXIOS.post("/api/app/user/delete-photographer-portfolio-image", {
    urls,
  });
}

function* deletePortfolioImagesGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(deletePortfolioImages, action?.payload);
    if (response.status === 200) {
      toast.success(response?.data?.message || "Images Deleted");
    }
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(deleteImagesPortFolio(action?.payload));
    yield put(setSettingLoader(false));
  }
}

async function deleteRenamePortfolioFolder(data) {
  const { folderName, newFolderName, action } = data || {};

  let params = {
    folderName,
    action,
  };

  if (action === "RENAME") params.newFolderName = newFolderName;

  return AXIOS.post("/api/app/user/delete-rename-portfolio-folder", params);
}

function* deleteRenamePortfolioFolderGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(deleteRenamePortfolioFolder, action?.payload);
    if (response.status === 200) {
      toast.success(response?.data?.message || "Folder Deleted");
    }
  } catch (e) {
    toast.error(e?.response?.data?.error?.[0] || "Something went wrong");
  } finally {
    yield put(deleteOrRenameFolder(action?.payload));
    yield put(setSettingLoader(false));
  }
}

async function addCreditsToWallet(data) {
  const {
    credits,
    frontendType,
    currency = "INR",
    followUpAction,
    followUpActionData,
  } = data || {};

  return AXIOS.post("/api/app/user/create-order-add-credits-userwallets", {
    credits,
    currency,
    frontendType,
    ...(followUpAction && { followUpAction }),
    ...(followUpActionData && { followUpActionData }),
  });
}

function* addCreditsToWalletGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(addCreditsToWallet, action?.payload);
    if (response.status === 200 || response.status === 201) {
      yield put(setCreditsAddResponse(response?.data?.data));
    }
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSettingLoader(false));
  }
}

async function getUserWallet() {
  return AXIOS.get("/api/app/user/get-user-wallet");
}

function* getUserWalletGenerator() {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(getUserWallet);
    if (response.status === 200 || response.status === 201) {
      yield put(setUserWallet(response?.data?.data));
    }
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSettingLoader(false));
  }
}

async function uploadAlbumLogo(data) {
  const { logo } = data || {};
  const formData = new FormData();
  formData.append("logo", logo);
  return AXIOS.put("/api/app/user/upload-photographer-album-logo", formData);
}

function* uploadAlbumLogoGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(uploadAlbumLogo, action?.payload);
    toast.success(response?.data?.message);
  } catch (e) {
    toast.error(e?.response?.data?.error);
  } finally {
    yield put(setSettingLoader(false));
  }
}

async function getAlbumDetails(data) {
  const { groupCode } = data || {};
  return AXIOS.get("/api/app/group/group-digital-album", {
    params: { groupCode },
  });
}

function* getAlbumDetailsGenerator(action) {
  try {
    yield put(setSettingLoader(true));
    const response = yield call(getAlbumDetails, action?.payload);
    yield put(setDigitalAlbum(response?.data?.data));
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSettingLoader(false));
  }
}

async function deleteImageFromAlbum(data) {
  const { imageIds, groupId } = data || {};
  return AXIOS.post("/api/app/group/delete-digital-album-image", {
    imageIds,
    groupId,
  });
}

function* deleteImageFromAlbumGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(deleteImageFromAlbum, action?.payload);
    toast.success(response?.data?.message);
    yield put(setDigitalAlbum(response?.data?.data));
  } catch (e) {
    toast.error(e?.response?.data?.error);
  } finally {
    yield put(setSaveLoader(false));
  }
}

async function changeAlbumImageOrder(data) {
  const { imageIds, groupId } = data || {};
  return AXIOS.post("/api/app/group/change-digital-album-order", {
    imageIds,
    groupId,
  });
}

function* changeAlbumImageOrderGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(changeAlbumImageOrder, action?.payload);
    toast.success(response?.data?.message);
    yield put(setDigitalAlbum(response?.data?.data));
  } catch (e) {
    toast.error(e?.response?.data?.error);
  } finally {
    yield put(setSaveLoader(false));
  }
}

async function updateAlbumColor(data) {
  const { groupId, color } = data || {};
  return AXIOS.post("/api/app/group/update-digital-album-color", {
    groupId,
    color,
  });
}

function* updateAlbumColorGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(updateAlbumColor, action?.payload);
    if (!action?.payload?.initial) toast.success(response?.data?.message);
    yield put(setDigitalAlbum(response?.data?.data));
  } catch (e) {
    toast.error(e?.response?.data?.message);
    yield put(setDigitalAlbum({ digitalAlbum: { notSubscribed: true } }));
  } finally {
    yield put(setSaveLoader(false));
  }
}

async function updateAlbumPin(data) {
  const { groupId, password, deleteDigitalAlbumPassword } = data || {};
  return AXIOS.post("/api/app/group/update-digital-album-password", {
    groupId,
    password,
    deleteDigitalAlbumPassword,
  });
}

function* updateAlbumPinGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(updateAlbumPin, action?.payload);
    toast.success(response?.data?.message);
    yield put(setDigitalAlbum(response?.data?.data));
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSaveLoader(false));
  }
}

async function toggleBlockParticipant(data) {
  const { groupId, participantId, blockUser } = data;

  return AXIOS.post("/api/app/group/block-unblock-group-participant", {
    groupId,
    participantId,
    blockUser,
  });
}

function* toggleBlockParticipantGenerator(action) {
  try {
    yield put(setSaveLoader(true));
    const response = yield call(toggleBlockParticipant, action?.payload);
    yield put(
      removeParticipantFromGroup({
        ...(response?.data || {}),
        ...action.payload,
      })
    );
    toast.success(response?.data?.message);
  } catch (e) {
    toast.error(e?.response?.data?.message);
  } finally {
    yield put(setSaveLoader(false));
  }
}

export function* settingsSaga() {
  yield takeLatest(
    GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
    getGroupDetailsAndOtherSettingsGenerator
  );
  yield takeLatest(
    GET_GROUP_DESIGNA_AND_SETTINGS,
    getGroupDesignaAndSettingsGenerator
  );
  yield takeLatest(
    PUT_GROUP_GENERAL_SETTINGS,
    putGroupGeneralSettingsGenerator
  );
  yield takeLatest(
    PATCH_GROUP_PRIVACY_SETTINGS,
    patchGroupPrivacySettingsGenerator
  );
  yield takeLatest(
    PATCH_GROUP_PARTICIPANTS_MAKE_ADMIN,
    patchGroupParticipantsMakeAdminGenerator
  );
  yield takeLatest(PATCH_LEAVE_GROUP, patchLeaveGroupGenerator);

  yield takeLatest(
    POST_GROUP_DESIGN_AND_SETTING,
    postGroupDesignAndSettingGenerator
  );
  yield takeLatest(GET_BUSINESS_BRANDING, getBusinessBrandingGenerator);
  yield takeLatest(POST_BUSINESS_BRANDING, postBusinessBrandingGenerator);
  yield takeLatest(POST_BUSINESS_LOGO, putBusinessLogoGenerator);
  yield takeLatest(GET_USER_DETAILS, getUserDetailsGenerator);
  yield takeLatest(REMOVE_PARTICIPANT, removeParticipantGenerator);
  yield takeLatest(GET_FAVOURITE_IMAGES, getFavoriteGenerator);
  yield takeLatest(
    SEND_OTP_TO_UPDATE_PHONE_OR_EMAIL,
    sendOtpToUpdatePhoneOrEmailGenerator
  );
  yield takeLatest(
    VERIFY_OTP_TO_UPDATE_PHONE_OR_EMAIL,
    verifyOtpToUpdatePhoneOrEmailGenerator
  );
  yield takeLatest(SET_USER_PROFILE, setUserProfileGenerator);
  yield takeLatest(DELETE_GROUP_ICON, deleteGroupIconGenerator);
  yield takeLatest(GET_USER_ANALYTICS, getUserAnalyticsGenerator);
  yield takeLatest(SEND_USER_CLICK_ANALYTICS, sendUserClickAnalyticsGenerator);
  yield takeLatest(GET_GROUP_ADMIN_TOKEN, getGroupAdminTokenGenerator);
  yield takeLatest(POST_BUSINESS_WATERMARK, putBusinessWatermarkGenerator);
  yield takeLatest(DELETE_WATERMARK, postDeleteWatermarkGenerator);
  yield takeLatest(POST_WATERMARK_OPTIONS, postWatermarkOptionsGenerator);
  yield takeLatest(GET_BRANCH_URL, getBranchUrlGenerator);
  yield takeLatest(PUT_COVER, putCoverImgGenerator);
  yield takeLatest(
    PUT_PHOTOGRAPHER_PORTFOLIO,
    putPhotographerPortfolioGenerator
  );
  yield takeLatest(DELETE_MY_ACCOUNT, deletAccountGenerator);
  yield takeLatest(DELETE_PORTFOLIO_IMAGE, deletePortfolioImagesGenerator);
  yield takeLatest(
    DELETE_RENAME_PORTFOLIO_FOLDER,
    deleteRenamePortfolioFolderGenerator
  );
  yield takeLatest(ADD_CREDITS_TO_WALLET, addCreditsToWalletGenerator);
  yield takeLatest(GET_USER_WALLET, getUserWalletGenerator);
  yield takeLatest(UPLOAD_ALBUM_LOGO, uploadAlbumLogoGenerator);
  yield takeLatest(GET_ALBUM_DETAILS, getAlbumDetailsGenerator);
  yield takeLatest(DELETE_ALBUM_IMAGE, deleteImageFromAlbumGenerator);
  yield takeLatest(CHANGE_ALBUM_IMAGE_ORDER, changeAlbumImageOrderGenerator);
  yield takeLatest(UPDATE_ALBUM_COLOR, updateAlbumColorGenerator);
  yield takeLatest(UPDATE_ALBUM_PIN, updateAlbumPinGenerator);
  yield takeLatest(TOGGLE_BLOCK_PARTICIPANT, toggleBlockParticipantGenerator);
}
