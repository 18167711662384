export const CURRENCIES = [
  {
    name: "INR",
    symbol: "₹",
    logo: "/assets/images/icons/portfolio/inr.png",
    step: 5,
  },
  {
    name: "USD",
    symbol: "$",
    logo: "/assets/images/icons/portfolio/usd.png",
    step: 0.5,
  },
  {
    name: "GBP",
    symbol: "£",
    logo: "/assets/images/icons/portfolio/gbp.png",
    step: 0.5,
  },
  {
    name: "EUR",
    symbol: "€",
    logo: "/assets/images/icons/portfolio/eur.png",
    step: 0.5,
  },
];

export const SERVICES = [
  {
    name: "Traditional Photography",
    price: "traditional_photography_price",
    enabled: "traditional_photography_enabled",
    serviceName: "traditional_photography_name",
    serviceImage: "traditional_photography_image",
    image: "/assets/images/icons/portfolio/traditional.png",
  },
  {
    name: "Candid Photography",
    price: "candid_photography_price",
    enabled: "candid_photography_enabled",
    serviceName: "candid_photography_name",
    serviceImage: "candid_photography_image",
    image: "/assets/images/icons/portfolio/candid.png",
  },
  {
    name: "Photo Album",
    price: "photo_album_price",
    enabled: "photo_album_enabled",
    serviceName: "photo_album_name",
    serviceImage: "photo_album_image",
    image: "/assets/images/icons/portfolio/album.png",
  },
  {
    name: "Pre-Wedding Photography",
    price: "prewedding_shoot_price",
    enabled: "prewedding_shoot_enabled",
    serviceName: "prewedding_shoot_name",
    serviceImage: "prewedding_shoot_image",
    image: "/assets/images/icons/portfolio/prewed.png",
  },
  {
    name: "Traditional Videography",
    price: "traditional_videography_price",
    enabled: "traditional_videography_enabled",
    serviceName: "traditional_videography_name",
    serviceImage: "traditional_videography_image",
    image: "/assets/images/icons/portfolio/tradvideo.png",
  },
  {
    name: "Cinematic Videography",
    price: "cinematic_videography_price",
    enabled: "cinematic_videography_enabled",
    serviceName: "cinematic_videography_name",
    serviceImage: "cinematic_videography_image",
    image: "/assets/images/icons/portfolio/cinematic.png",
  },
];

export const ICONS_LIST = [
  {
    title: "Wedding",
    icons: [
      "/assets/images/icons/portfolio/wedding/1.png",
      "/assets/images/icons/portfolio/wedding/2.png",
      "/assets/images/icons/portfolio/wedding/3.png",
      "/assets/images/icons/portfolio/wedding/4.png",
      "/assets/images/icons/portfolio/wedding/5.png",
      "/assets/images/icons/portfolio/wedding/6.png",
      "/assets/images/icons/portfolio/wedding/7.png",
      "/assets/images/icons/portfolio/wedding/8.png",
      "/assets/images/icons/portfolio/wedding/9.png",
      "/assets/images/icons/portfolio/wedding/10.png",
    ],
  },
  {
    title: "Nature",
    icons: [
      "/assets/images/icons/portfolio/nature/1.png",
      "/assets/images/icons/portfolio/nature/2.png",
      "/assets/images/icons/portfolio/nature/3.png",
      "/assets/images/icons/portfolio/nature/4.png",
      "/assets/images/icons/portfolio/nature/5.png",
      "/assets/images/icons/portfolio/nature/6.png",
      "/assets/images/icons/portfolio/nature/7.png",
      "/assets/images/icons/portfolio/nature/8.png",
      "/assets/images/icons/portfolio/nature/9.png",
      "/assets/images/icons/portfolio/nature/10.png",
      "/assets/images/icons/portfolio/nature/11.png",
      "/assets/images/icons/portfolio/nature/12.png",
      "/assets/images/icons/portfolio/nature/13.png",
    ],
  },
  {
    title: "Street photography",
    icons: [
      "/assets/images/icons/portfolio/street/1.png",
      "/assets/images/icons/portfolio/street/2.png",
      "/assets/images/icons/portfolio/street/3.png",
      "/assets/images/icons/portfolio/street/4.png",
      "/assets/images/icons/portfolio/street/5.png",
      "/assets/images/icons/portfolio/street/6.png",
      "/assets/images/icons/portfolio/street/7.png",
      "/assets/images/icons/portfolio/street/8.png",
      "/assets/images/icons/portfolio/street/9.png",
    ],
  },
  {
    title: "Events & Corporate",
    icons: [
      "/assets/images/icons/portfolio/corporate/1.png",
      "/assets/images/icons/portfolio/corporate/2.png",
      "/assets/images/icons/portfolio/corporate/3.png",
      "/assets/images/icons/portfolio/corporate/4.png",
      "/assets/images/icons/portfolio/corporate/5.png",
      "/assets/images/icons/portfolio/corporate/6.png",
      "/assets/images/icons/portfolio/corporate/7.png",
      "/assets/images/icons/portfolio/corporate/8.png",
      "/assets/images/icons/portfolio/corporate/9.png",
      "/assets/images/icons/portfolio/corporate/10.png",
      "/assets/images/icons/portfolio/corporate/11.png",
    ],
  },
  {
    title: "Family",
    icons: [
      "/assets/images/icons/portfolio/family/1.png",
      "/assets/images/icons/portfolio/family/2.png",
      "/assets/images/icons/portfolio/family/3.png",
      "/assets/images/icons/portfolio/family/4.png",
      "/assets/images/icons/portfolio/family/5.png",
    ],
  },
  {
    title: "Fashion, music & sports",
    icons: [
      "/assets/images/icons/portfolio/fashion/1.png",
      "/assets/images/icons/portfolio/fashion/2.png",
      "/assets/images/icons/portfolio/fashion/3.png",
      "/assets/images/icons/portfolio/fashion/4.png",
      "/assets/images/icons/portfolio/fashion/5.png",
      "/assets/images/icons/portfolio/fashion/6.png",
    ],
  },
  {
    title: "Food, product & art",
    icons: [
      "/assets/images/icons/portfolio/food/1.png",
      "/assets/images/icons/portfolio/food/2.png",
      "/assets/images/icons/portfolio/food/3.png",
      "/assets/images/icons/portfolio/food/4.png",
      "/assets/images/icons/portfolio/food/5.png",
      "/assets/images/icons/portfolio/food/6.png",
      "/assets/images/icons/portfolio/food/7.png",
      "/assets/images/icons/portfolio/food/8.png",
      "/assets/images/icons/portfolio/food/9.png",
      "/assets/images/icons/portfolio/food/10.png",
      "/assets/images/icons/portfolio/food/11.png",
      "/assets/images/icons/portfolio/food/12.png",
    ],
  },
  {
    title: "Miscellaneous",
    icons: [
      "/assets/images/icons/portfolio/misc/1.png",
      "/assets/images/icons/portfolio/misc/2.png",
      "/assets/images/icons/portfolio/misc/3.png",
      "/assets/images/icons/portfolio/misc/4.png",
      "/assets/images/icons/portfolio/misc/5.png",
      "/assets/images/icons/portfolio/misc/6.png",
    ],
  },
];

export const NON_TRANSFERABLE_FOLDERS = [
  "purchased",
  "my-photos",
  "all-photos",
  "all-videos",
  "favorites",
  "deleted",
];

export const MODALS = {
  UPLOAD_LIMIT_EXCEED: "UPLOAD_LIMIT_EXCEED",
  SUBSCRIPTION: "SUBSCRIPTION",
  USER_AGENT_ERROR: "USER_AGENT_ERROR",
  UPLOAD: "UPLOAD",
  SERVICE_EDIT: "SERVICE_EDIT",
  NONE: "",
};

export const INPUT_FIELDS = {
  UPI: [
    {
      key: "upiId",
      label: "UPI ID",
      placeholder: "username@upi",
    },
    {
      key: "upiNumber",
      label: "UPI Phone Number",
      placeholder: "9876543210",
    },
  ],
  BANK: [
    {
      key: "bankName",
      label: "Bank Name",
      type: "text",
    },
    {
      key: "accountName",
      label: "Account Holder Name",
      type: "text",
    },
    {
      key: "accountNumber",
      label: "Account Number",
      type: "number",
    },
    {
      key: "accountType",
      label: "Account Type",
      type: "text",
      condition: (currency) => currency != "GBP",
    },
    {
      key: "bankCode",
      label: (currency) =>
        currency == "GBP"
          ? "Sort Code"
          : currency == "USD"
          ? "Routing Number"
          : currency == "EUR"
          ? "IBAN"
          : "IFSC Code",
      type: "text",
    },
  ],
};
