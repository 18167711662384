import useDetectUserAgent from "hooks/useDetectUserAgent";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import DefaultHeader from "views/components/header/DefaultHeader";
import AlbumDownload from "views/download/AlbumDownload";
import ProtectedRoute from "./ProtectedRoute";
import "./styles/downloadRoute.css";

const DownloadRoutes = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deviceType, setDeviceType] = useState({ type: "desktop" });
  useDetectUserAgent(setDeviceType);

  const { isAdmin } = useSelector(
    (state) => state.settings.leaveParticipantSettings
  );
  const { allowDownload, bulkDownload } = useSelector(
    (state) => state.settings.downloadSettings
  );

  // useEffect(() => {
  //   if (!allowDownload && !isAdmin) {
  //     if (allowDownload !== undefined && isAdmin !== undefined) {
  //       // navigate("/groups");
  //     }
  //   }
  // }, [allowDownload, isAdmin]);

  useEffect(() => {
    if (!params.has("groupId") || !params.get("groupId")) {
      navigate("/groups");
    }
  }, [dispatch, navigate, params]);

  useEffect(() => {
    if (deviceType?.type === "mobile") {
      navigate("/groups");
    }
  }, [deviceType?.type, navigate]);

  const downloadAllowed =
    (bulkDownload && allowDownload) || (isAdmin && bulkDownload);

  const backUrl = `/gallery?groupId=${params.get("groupId")}`;

  return (
    <ProtectedRoute>
      <DefaultHeader goBack={backUrl} />
      {!downloadAllowed ? (
        <div className="background-image">
          <h3 className="mb-3 text-center header-text">
            Album Downloading is disabled
          </h3>
          <p className="text-center description-text">
            Contact group admin for access.
          </p>
        </div>
      ) : (
        <div className="download-page">
          <div className="download-page-content">
            <div className="p-3">
              <AlbumDownload />
            </div>
          </div>
        </div>
      )}
    </ProtectedRoute>
  );
};

export default DownloadRoutes;
