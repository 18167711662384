import React from "react";
import ConfirmationModal from "./ConfirmationModal";
import { DownloadButtons } from "./UserAgentModal";
import ENV from "utils/helpers/env";
/**
 * @typedef {object} PhotographerErrorModalProps
 * @property {boolean} show
 * @property {function} hide
 * @property {object} deviceType
 * @property {("login"|"join")} type
 */
const PhotographerErrorModal = ({ show, hide, deviceType, type = "join" }) => {
  return (
    <ConfirmationModal
      show={show}
      title={`Can't ${
        type == "login"
          ? "Login"
          : type === "join"
          ? "Join this Group"
          : "Create Group"
      }`}
      confirmText=""
      cancelText=""
      onCancel={hide}
      className="text-center"
      footer={false}
      animation={true}
    >
      <div>
        {type == "login" ? (
          <p className="font-15 mb-3">
            {ENV.WL_REMOVE_KW_POPUP ? (
              <>You are restricted from logging in on this website.</>
            ) : (
              <>
                You are restricted from logging in on this website. Please use
                Kwikpic&apos;s&nbsp;
                <a
                  href="https://www.kwikpic.in"
                  target="_blank"
                  rel="noreferrer"
                >
                  website
                </a>
                &nbsp;or mobile app to access the services.
              </>
            )}
          </p>
        ) : type == "join" ? (
          <p className="font-15 mb-3">
            {ENV.WL_REMOVE_KW_POPUP ? (
              <>You can only join groups created by {ENV.WL_BUSINESS_NAME}.</>
            ) : (
              <>
                You can only join groups created by the photographer. Please use
                Kwikpic&apos;s&nbsp;
                <a
                  href="https://www.kwikpic.in"
                  target="_blank"
                  rel="noreferrer"
                >
                  website
                </a>
                &nbsp;or mobile app to join other groups.
              </>
            )}
          </p>
        ) : (
          <p className="font-15 mb-3">
            {ENV.WL_REMOVE_KW_POPUP ? (
              <>Only the website owner can create groups.</>
            ) : (
              <>
                You can&apos;t create groups on this website. Please use
                Kwikpic&apos;s&nbsp;
                <a
                  href="https://www.kwikpic.in"
                  target="_blank"
                  rel="noreferrer"
                >
                  website
                </a>
                &nbsp;or mobile app to create groups.
              </>
            )}
          </p>
        )}
        {ENV.WL_REMOVE_KW_POPUP ? null : (
          <div className="mx-auto">
            <DownloadButtons
              variant={2}
              deviceType={deviceType}
              showKwikpic={true}
              onHide={hide}
            />
          </div>
        )}
      </div>
    </ConfirmationModal>
  );
};

export default PhotographerErrorModal;
