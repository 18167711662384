import { getLocalStorageUser } from "./localstorage";

const appendTS = `?v=${process.env.REACT_APP_BUILD_TIMESTAMP}`;

const ENV = {
  // app urls

  WL_APP_STORE_URL: process.env.REACT_APP_appStoreUrl,
  WL_PLAY_STORE_URL: process.env.REACT_APP_playStoreUrl,
  WL_APP_SHORT_URL: process.env.REACT_APP_appShortUrl,
  WL_HAS_APP:
    process.env.REACT_APP_appStoreUrl || process.env.REACT_APP_playStoreUrl,

  // basic

  WL_USERID: process.env.REACT_APP_userId,
  WL_MAIL: process.env.REACT_APP_whiteLabelMail,
  WL_BRANCH_KEY: process.env.REACT_APP_branchKey,
  WL_DEFAULT_COUNTRY_CODE: process.env.REACT_APP_defaultCC,
  WL_ALLOW_PHONE_LOGIN: process.env.REACT_APP_allowPhoneLogin === "true",
  WL_ALLOW_EMAIL_LOGIN: process.env.REACT_APP_allowEmailLogin === "true",
  WL_WEB_SHARE_ONLY: process.env.REACT_APP_webShareOnly === "true",
  WL_DISABLE_POWERED_BY: process.env.REACT_APP_disablePoweredBy === "true",
  WL_REMOVE_KW_POPUP: process.env.REACT_APP_removeKwikpicPopup === "true",
  WL_DISTRIBUTOR: process.env.REACT_APP_isDistributor === "true",
  WL_SHOW_MUTIPLE_BRANDING:
    process.env.REACT_APP_showMulitpleBranding === "true",
  WL_PHOTOGRAPHER_ID: process.env.REACT_APP_photographerId,
  WL_DEVICE_APP: process.env.REACT_APP_deviceApp,
  WL_FALLBACK_APP_DOWNLOAD_TO_KWIKPIC:
    process.env.REACT_APP_fallbackAppDownloadToKwikpic === "true",

  // preferences

  WL_HIDE_TUTORIAL: process.env.REACT_APP_hideTutorial === "true",
  WL_HIDE_HELPDESK: process.env.REACT_APP_hideHelpdesk === "true",
  WL_HIDE_GROUP_JOIN_TUTORIAL:
    process.env.REACT_APP_hideGroupJoinTutorial === "true",

  // web text

  WL_BUSINESS_NAME: process.env.REACT_APP_businessName,
  WL_HOST: process.env.REACT_APP_host,
  WL_WELCOME_MESSAGE: process.env.REACT_APP_welcomeMessage,
  WL_PORTFOLIO_WELCOME_MESSAGE: process.env.REACT_APP_portfolioWelcomeMessage,
  WL_LANDING_WELCOME_MESSAGE: process.env.REACT_APP_landingPageWelcomeMessage,
  WL_TAGLINE: process.env.REACT_APP_tagline,
  WL_QUOTE: process.env.REACT_APP_quote,
  WL_LOGIN_QUOTE: process.env.REACT_APP_loginQuote,
  WL_OTP_QUOTE: process.env.REACT_APP_otpQuote,
  WL_SELECT_USER_QUOTE: process.env.REACT_APP_selectUserQuote,
  WL_REGISTER_USER_QUOTE: process.env.REACT_APP_registerUserQuote,
  WL_JOIN_QUOTE: process.env.REACT_APP_joinQuote,

  // web links

  WL_SEE_PHOTOS_LABEL: process.env.REACT_APP_seePhotosLabel,
  WL_SEE_PHOTOS_URL: process.env.REACT_APP_seePhotosUrl,
  WL_BOOK_NOW_LABEL: process.env.REACT_APP_bookNowLabel,
  WL_BOOK_NOW_URL: process.env.REACT_APP_bookNowUrl,
  WL_LINK_1_LABEL: process.env.REACT_APP_linkLabel1,
  WL_LINK_1_URL: process.env.REACT_APP_linkUrl1,
  WL_LINK_2_LABEL: process.env.REACT_APP_linkLabel2,
  WL_LINK_2_URL: process.env.REACT_APP_linkUrl2,
  WL_LINK_3_LABEL: process.env.REACT_APP_linkLabel3,
  WL_LINK_3_URL: process.env.REACT_APP_linkUrl3,
  WL_LINK_4_LABEL: process.env.REACT_APP_linkLabel4,
  WL_LINK_4_URL: process.env.REACT_APP_linkUrl4,
  WL_LINK_5_LABEL: process.env.REACT_APP_linkLabel5,
  WL_LINK_5_URL: process.env.REACT_APP_linkUrl5,
  WL_LINK_6_LABEL: process.env.REACT_APP_linkLabel6,
  WL_LINK_6_URL: process.env.REACT_APP_linkUrl6,

  WL_TNC_LINK: process.env.REACT_APP_tncLink,
  WL_PP_LINK: process.env.REACT_APP_ppLink,
  WL_REFUND_LINK: process.env.REACT_APP_refundLink,

  WL_FOOTER: [
    {
      label: process.env.REACT_APP_footerLabel1,
      url: process.env.REACT_APP_footerUrl1,
    },
    {
      label: process.env.REACT_APP_footerLabel2,
      url: process.env.REACT_APP_footerUrl2,
    },
    {
      label: process.env.REACT_APP_footerLabel3,
      url: process.env.REACT_APP_footerUrl3,
    },
  ],

  WL_CONTACT_WA: process.env.REACT_APP_contactWA,
  WL_CONTACT_EMAIL: process.env.REACT_APP_contactEmail,

  // images

  // general
  WL_FAVICON: process.env.REACT_APP_favicon + appendTS,
  WL_PRIMARY_LOGO: "/assets/images/global-images/logo.png" + appendTS,
  WL_WHITE_LOGO: "/assets/images/global-images/logo-white.png" + appendTS,
  WL_COLORED_LOGO:
    "/assets/images/global-images/logo_colored_white.png" + appendTS,

  // landing
  WL_COVER_IMAGE:
    (process.env.REACT_APP_coverImage ||
      "/assets/images/whitelabel/cover.png") + appendTS,
  WL_QUOTE_BACKGROUND:
    (process.env.REACT_APP_quoteBackground ||
      "/assets/images/whitelabel/quote-bg.png") + appendTS,
  WL_SERVICES_BACKGROUND:
    (process.env.REACT_APP_servicesBackground ||
      "/assets/images/whitelabel/services.png") + appendTS,

  // auth
  WL_LOGIN_BANNER:
    (process.env.REACT_APP_loginBanner ||
      "/assets/images/auth/login-banner.jpg") + appendTS,
  WL_REGISTER_USER_BANNER:
    (process.env.REACT_APP_registerUserBanner ||
      "/assets/images/auth/register-user-banner.jpg") + appendTS,
  WL_OTP_BANNER:
    (process.env.REACT_APP_otpBanner || "/assets/images/auth/otp-banner.jpg") +
    appendTS,
  WL_SELECT_USER_BANNER:
    (process.env.REACT_APP_selectUserBanner ||
      "/assets/images/auth/select-user-banner.jpg") + appendTS,
  WL_JOIN_BANNER:
    (process.env.REACT_APP_joinBanner ||
      "/assets/images/auth/join-banner.jpg") + appendTS,
};

export const currentUserIsOwner = () => {
  return ENV.WL_USERID === getLocalStorageUser()._id;
};

export default ENV;
