import React, { useEffect, useState } from "react";
import SettingPageHeading from "../components/SettingPageHeading";
import DefaultInput from "../../components/input/DefaultInput";
/* import { ProgressBar } from "react-bootstrap"; */
// import UpgradeButton from "../components/button/UpgradeButton";
import {
  GET_MY_SUBSCRIPTION_PLAN,
  GET_USER_ANALYTICS,
  // GET_USER_DETAILS,
  SEND_USER_CLICK_ANALYTICS,
  SET_USER_PROFILE,
  // GET_GROUP_DETAILS_AND_OTHER_SETTINGS,
} from "redux-store/sagas/saga-actions";
import { useDispatch, useSelector } from "react-redux";
import EnterPhoneModal from "../components/EnterPhoneModal";
// import { useSearchParams } from "react-router-dom";
import {
  convertToGB,
  deepEqual,
  isValidUserName,
} from "../../../utils/helpers";
import usePreventUnsavedNavigation from "hooks/usePreventUnsavedNavigation";
import SubscriptionModal from "views/subscription";
import useSubscription from "views/subscription/hooks/useSubscription";
import DefaultSwitch from "views/components/input/DefaultSwitch";
import css from "./index.module.css";
import SecondarySmallBtn from "views/components/button/SecondarySmallBtn";
import ConfirmationModal from "views/components/modals/ConfirmationModal";
import { useSearchParams } from "react-router-dom";
import { Accordion, Modal } from "react-bootstrap";
import UserAgentErrorModal from "views/components/modals/UserAgentModal";
import dayjs from "dayjs";
import useDetectUserAgent from "hooks/useDetectUserAgent";
import cx from "classnames";
import PLANS from "constants/plans";
import PasswordModal from "views/main/home/components/PasswordModal";
import ANALYTICS from "constants/analyticsKeys";

const fieldsNames = ["name", "lastName"];

const INFO_MODALS = {
  STORAGE: "storage",
  SUBSCRIPTION: "subscription",
  USERAGENTERROR: "userAgentError",
  HIGHRES: "highRes",
  OGQUALITY: "ogQuality",
  NONE: "",
};

export const MODAL_TYPES = {
  EMAIL: "Email",
  PASSWORD: "Password",
  PHONE: "Phone",
  NONE: "",
};

const ProfileSettings = () => {
  const initialValueRef = React.useRef({});
  const [settings, setSettings] = React.useState({
    countryCode: "+91",
  });
  const [modal, setModal] = React.useState({
    status: false,
    type: MODAL_TYPES.NONE,
  });
  const [errors, setErrors] = useState([]);
  const [infoModal, setInfoModal] = useState(INFO_MODALS.NONE);
  const [deviceType, setDeviceType] = useState({ type: "desktop" });
  useDetectUserAgent(setDeviceType);

  const dispatch = useDispatch();
  const { userProfileDetails, userAnalytics } = useSelector(
    (state) => state.settings
  );

  const { getMyActivePlan } = useSubscription();
  const { plansLoading } = useSelector((state) => state.subscription);

  const [params] = useSearchParams();
  const token = params.has("token");
  const noback = params.has("noback");

  const { displayText, status, period, interval, current_end, plan_id } =
    getMyActivePlan() || {};
  const { name, phoneNumber, email, lastName, password, userType, avatar } =
    userProfileDetails || {};

  const {
    totalUtilisation,
    uploadLimit,
    videoUploads,
    videoDeletes,
    photoUploads,
    photoDeletes,
    highResPhotoUploads,
    highResPhotoDeletes,
    groups,
    uploadVideoLimit,
  } = userAnalytics;

  //check for present images exceeding total allowed images
  const storageProgress =
    totalUtilisation > uploadLimit
      ? 100
      : Math.round((totalUtilisation / uploadLimit) * 100);

  const videoProgress = Math.round(
    ((videoUploads - videoDeletes) / uploadVideoLimit) * 100
  );

  useEffect(() => {
    dispatch({ type: GET_USER_ANALYTICS });
    dispatch({ type: GET_MY_SUBSCRIPTION_PLAN });
  }, [dispatch]);

  useEffect(() => {
    initialValueRef.current = { name, phoneNumber, email, lastName, password };
    setSettings({
      name,
      phoneNumber,
      email,
      lastName,
      password,
    });
  }, [userProfileDetails]);

  usePreventUnsavedNavigation({
    message: "You have unsaved changes. Do you really want to leave this page?",
    block: !deepEqual(initialValueRef.current, settings) && !token,
  });

  const handleSave = () => {
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.PROFILE_SAVE] },
    });
    const { tocAccepted } = userProfileDetails || {};

    const allErrors = validate(settings?.name ?? "", settings?.lastName ?? "");
    if (allErrors?.length > 0) {
      setErrors(allErrors);
      return;
    }

    setErrors([]);

    const phoneNumber =
      settings?.newPhoneNumber || settings?.phoneNumber || null;
    const email = settings?.newEmail || settings?.email || null;

    dispatch({
      type: SET_USER_PROFILE,
      payload: {
        name: settings?.name,
        countryCode: settings?.countryCode || "+91",
        lastName: settings?.lastName || "",
        tocAccepted,
        ...(phoneNumber && { phoneNumber }),
        ...(email && { email }),
      },
    });
    initialValueRef.current = { ...settings };
  };

  const handleToggle = (key) => {
    dispatch({
      type: SET_USER_PROFILE,
      payload: {
        name: userProfileDetails?.name,
        lastName: userProfileDetails?.lastName,
        tocAccepted: userProfileDetails?.tocAccepted,
        [key]: !userProfileDetails?.[key],
      },
    });
  };

  return (
    <div>
      <SettingPageHeading
        title="Profile Setting"
        onClick={() => handleSave()}
        btnTitle="Save"
        goBack={token && !noback ? () => history.back() : false}
      />
      <div className="row">
        <div className="col-lg-5">
          {userType === "USER" && (
            <div className="col-12 mt-4">
              <div className={css["profile-container"]}>
                <div>
                  <img
                    src={avatar || "/assets/images/icons/user.png"}
                    alt="user avatar"
                  />
                  <p>Selfie</p>
                </div>
                <button
                  onClick={() => {
                    setInfoModal(INFO_MODALS.USERAGENTERROR);
                  }}
                >
                  <img
                    src="/assets/images/icons/pencil.png"
                    alt="edit profile"
                  />
                  <span>Edit Photo</span>
                </button>
              </div>
              <div className="dropdown-divider mt-4 mb-0" />
            </div>
          )}

          <div className="col-12">
            <div className="mt-4">
              <h4>First Name</h4>
              <div className="d-flex">
                <DefaultInput
                  value={settings?.name || ""}
                  onChange={(e) => {
                    setSettings((prev) => ({
                      ...prev,
                      name: e.target?.value || "",
                    }));
                  }}
                  error={
                    errors.find((error) => error.field === fieldsNames[0])
                      ?.message
                  }
                  placeholder="Name"
                />
              </div>
            </div>
            <div className="mt-4">
              <h4>Last Name</h4>
              <div className="d-flex">
                <DefaultInput
                  value={settings?.lastName || ""}
                  onChange={(e) => {
                    setSettings((prev) => ({
                      ...prev,
                      lastName: e.target?.value || "",
                    }));
                  }}
                  error={
                    errors.find((error) => error.field === fieldsNames[1])
                      ?.message
                  }
                  placeholder="Lastname"
                />
              </div>
            </div>
            <div className="mt-4">
              <h4>Email ID</h4>
              <div className="d-flex">
                <DefaultInput
                  value={settings?.newEmail || settings?.email || ""}
                  placeholder="Email"
                  onClick={() => {
                    setModal({ status: true, type: MODAL_TYPES.EMAIL });
                  }}
                  // to bypass the warning
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="mt-4">
              <h4>Phone Number</h4>
              <div className="d-flex">
                <DefaultInput
                  value={
                    settings?.newPhoneNumber || settings?.phoneNumber || ""
                  }
                  onClick={() =>
                    setModal({ status: true, type: MODAL_TYPES.PHONE })
                  }
                  placeholder="Phone Number"
                  // to bypass the warning
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="mt-4">
              <h4>Password</h4>
              <div className="d-flex">
                <DefaultInput
                  value={settings?.password ? "⋆ ⋆ ⋆ ⋆ ⋆ ⋆ ⋆ ⋆" : ""}
                  onClick={() =>
                    setModal({ status: true, type: MODAL_TYPES.PASSWORD })
                  }
                  placeholder="Enter Password"
                  onChange={() => {}}
                />
              </div>
            </div>
          </div>
          <div className="col-12 d-lg-none d-block">
            <div className="dropdown-divider mt-4 mb-0" />
          </div>
        </div>
        <div className="col-lg-5 ms-lg-4">
          <div className="col-12 mt-4">
            <div className="d-flex align-items-center mb-2">
              <h4 className="m-0 me-2">Upload in Higher Resolution</h4>
              <DefaultSwitch
                isChecked={userProfileDetails?.highRes}
                onChange={() => {
                  if (userProfileDetails?.highRes) handleToggle("highRes");
                  else setInfoModal(INFO_MODALS.HIGHRES);

                  dispatch({
                    type: SEND_USER_CLICK_ANALYTICS,
                    payload: { buttons: [ANALYTICS.PROFILE_UPLOAD_HIGHRES] },
                  });
                }}
              />
            </div>
            <div className="d-flex align-items-start gap-1 mt-2">
              <img
                src="/assets/images/icons/info-plain.png"
                alt="info plain"
                className="align-self-center info-icon"
              />
              <p className="gray-text thick-font mb-0">
                1 High Resolution Upload = 2.5 Photos
              </p>
            </div>
          </div>
          <Accordion>
            <Accordion.Item className="border-0" eventKey={0}>
              <Accordion.Button className={css.accordion}>
                More Options
              </Accordion.Button>
              <Accordion.Body className="p-0 pb-4">
                <div className="d-flex align-items-center mb-2">
                  <h4 className="m-0 me-2">
                    Download in Original Quality&nbsp;
                    <br className={css.linebreak} />
                    (if available)
                  </h4>
                  <DefaultSwitch
                    isChecked={userProfileDetails?.downOQ || false}
                    onChange={
                      userProfileDetails?.downOQ
                        ? () => handleToggle("downOQ")
                        : () => setInfoModal(INFO_MODALS.OGQUALITY)
                    }
                  />
                </div>
                <div className="d-flex align-items-start gap-1 mt-2">
                  <img
                    src="/assets/images/icons/info-plain.png"
                    alt="info plain"
                    className="align-self-center info-icon"
                  />
                  <p className="gray-text thick-font mb-0">
                    Slow Downloads. Switch it on only for Prints or Backups
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="col-12">
            <div className="dropdown-divider mb-4"></div>
          </div>
          <div className="col-12">
            <div className="storageUtilization">
              <h4>Storage Utilization</h4>
              <div className="d-flex align-items-start gap-1 mt-2">
                <img
                  src="/assets/images/icons/info-plain.png"
                  alt="info plain"
                  className="align-self-center info-icon"
                />
                <p className="mb-0">
                  Deleted images will be reduced from upload count post 24
                  hours.
                </p>
              </div>
              <div className={css["storage-container"]}>
                <div>
                  <img src="/assets/images/icons/photos.png" alt="photos" />
                  <p
                    className={
                      storageProgress >= 100 ? css["full-storage"] : ""
                    }
                  >
                    <span>{Math.round(totalUtilisation)}</span>
                    &nbsp;of&nbsp;
                    {uploadLimit} Photos
                  </p>
                </div>
                <hr />
                <div>
                  <img
                    src="/assets/images/icons/video.png"
                    alt="videos"
                    className="py-1"
                  />
                  <p
                    className={videoProgress >= 100 ? css["full-storage"] : ""}
                  >
                    <span>{videoUploads - videoDeletes}</span>
                    &nbsp;of&nbsp;{uploadVideoLimit} Videos
                  </p>
                </div>
              </div>
              <SecondarySmallBtn
                title="Additional Info"
                className="mt-3 font-15"
                onClick={() => {
                  setInfoModal(INFO_MODALS.STORAGE);
                  dispatch({
                    type: SEND_USER_CLICK_ANALYTICS,
                    payload: { buttons: [ANALYTICS.PROFILE_STORAGE_INFO] },
                  });
                }}
              />
              {/* <span
                className={cx(
                  presentImagesCount >= userAnalytics?.uploadLimit
                    ? "text-full-storage"
                    : ""
                )}
              >
                {presentImagesCount}{" "}
                <small>of {userAnalytics?.uploadLimit} Photos</small>
              </span> */}
            </div>
          </div>
          <div className="col-12">
            <div className="dropdown-divider mt-4 mb-3" />
          </div>
          {!plansLoading ? (
            <div className="col-12">
              <div className="mt-4">
                <h4>Subscription</h4>
                <div className="planCard">
                  <h3>{displayText}</h3>
                  <p
                    className={cx("capitalize-first", {
                      "mb-0": !PLANS.FREE_PLANS[userType]?.includes(plan_id),
                    })}
                  >
                    {status}
                    &nbsp;-&nbsp;
                    {period === "monthly"
                      ? interval === 3
                        ? "Quarterly"
                        : "Monthly"
                      : "Yearly"}
                  </p>
                  {!PLANS.FREE_PLANS[userType]?.includes(plan_id) && (
                    <p className="m-0">
                      Expires on {dayjs.unix(current_end).format("DD/MM/YYYY")}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* <div className="col-12">
            <div className="dropdown-divider mt-4 mb-3" />
          </div>
          <div className="col-12">
            <div className="mt-4 downloadable">
              <h4>Downloadable Sizes</h4>
              <SelectRadioGroup
                select1="Kwikpic Optimized - High Quality"
                select2="Full Original Quality"
                id1="quality1"
                id2="quality2"
              />
            </div>
          </div> */}
        </div>
      </div>

      {modal.status && modal.type === MODAL_TYPES.PASSWORD && (
        <PasswordModal
          show={true}
          onClose={() => setModal({ status: false, type: MODAL_TYPES.NONE })}
          onCancel={() => setModal({ status: false, type: MODAL_TYPES.NONE })}
          closeButton={true}
        />
      )}
      <EnterPhoneModal
        show={modal.status && modal.type !== MODAL_TYPES.PASSWORD}
        type={modal?.type}
        setSettings={setSettings}
        handleClose={() => {
          setModal({ status: false, type: MODAL_TYPES.NONE });
        }}
      />
      <ConfirmationModal
        show={infoModal === INFO_MODALS.STORAGE}
        onCancel={() => setInfoModal(INFO_MODALS.NONE)}
        className={css["storage-modal"]}
        footer={false}
        size="lg"
        animation
      >
        <Modal.Header
          closeButton
          className="text-left border-0 p-0 justify-content-start m-0"
        >
          <h1 className="font-24 font-bold">Storage Utilization</h1>
        </Modal.Header>
        <div>
          <img src="/assets/images/icons/photos.png" alt="photos" />
          <p className={storageProgress >= 100 ? css["full-storage"] : ""}>
            <span>{Math.round(totalUtilisation)}</span>
            &nbsp;of&nbsp;
            {uploadLimit} Photos
          </p>
        </div>
        <div className="progress gap-0">
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{
              width: `${((photoUploads - photoDeletes) * 100) / uploadLimit}%`,
              "--bs-progress-bar-bg": "#2887af",
            }}
          />
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{
              width: `${
                ((highResPhotoUploads - highResPhotoDeletes) * 250) /
                uploadLimit
              }%`,
              "--bs-progress-bar-bg": "#44c08a",
            }}
          />
        </div>
        <div className={css.utilization}>
          <div>
            <UtilizationContent
              class="utilizationContent first"
              title="Standard Uploads"
              value={photoUploads || 0}
            />
            <UtilizationContent
              class="utilizationContent second"
              title="High Res Uploads"
              value={highResPhotoUploads || 0}
            />
            <UtilizationContent
              class="utilizationContent third"
              title="Storage Used"
              value={convertToGB(totalUtilisation, 2) + " GB"}
            />
          </div>
          <div>
            <UtilizationContent
              class="utilizationContent first"
              title="Standard Deletes"
              value={photoDeletes || 0}
            />
            <UtilizationContent
              class="utilizationContent second"
              title="High Res Deletes"
              value={highResPhotoDeletes || 0}
            />
            <UtilizationContent
              class="utilizationContent third"
              title="Total Groups"
              value={groups || 0}
            />
          </div>
        </div>
        <div className="dropdown-divider" />
        <div>
          <img src="/assets/images/icons/video-camera.png" alt="photos" />
          <p className={videoProgress >= 100 ? css["full-storage"] : ""}>
            <span>{videoUploads - videoDeletes}</span>
            &nbsp;of&nbsp;{uploadVideoLimit} Videos
          </p>
        </div>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={videoProgress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{
              width: `${videoProgress}%`,
              "--bs-progress-bar-bg": "#a46c96",
            }}
          />
        </div>
        <div className={css["utilization-vdo"]}>
          <UtilizationContent
            class="utilizationContent fourth"
            title="Video Uploads"
            value={videoUploads || 0}
          />

          <UtilizationContent
            class="utilizationContent fifth"
            title="Video Deletes"
            value={videoDeletes || 0}
          />
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        show={
          infoModal === INFO_MODALS.HIGHRES ||
          infoModal === INFO_MODALS.OGQUALITY
        }
        title={
          infoModal === INFO_MODALS.HIGHRES
            ? "High-res photos use more storage!"
            : "Slow Download Alert!"
        }
        confirmText="Yes"
        onCancel={() => setInfoModal(INFO_MODALS.NONE)}
        onConfirm={() => {
          infoModal === INFO_MODALS.HIGHRES
            ? handleToggle("highRes")
            : handleToggle("downOQ");

          setInfoModal(INFO_MODALS.NONE);
        }}
        footerClass="pt-0"
      >
        <p className="font-bold m-0 color-h font-15">
          <img
            src="/assets/images/icons/info-plain.png"
            alt="info plain"
            className="align-self-center info-icon mb-1 me-1"
          />
          {infoModal === INFO_MODALS.HIGHRES ? (
            "1 High Resolution Upload = 2.5 Photos"
          ) : (
            <>
              Original file sizes can be 20x bigger with just
              <br /> 5-10% improvement in quality
            </>
          )}
        </p>
        <br />
        {infoModal === INFO_MODALS.HIGHRES ? (
          <>
            <p className="font-15 font-bold color-h">
              Note: Switch it on only while uploading
              <br /> un-compressed original photos
            </p>
            <br />
            <p className="font-15 font-bold color-primary">
              Are you sure you want to enable
              <br /> Upload in Higher Resolution?
            </p>
          </>
        ) : (
          <>
            <p className="font-15 font-bold color-h">
              Switch it on only for&nbsp;
              <span className="color-primary">Prints or Backup</span>
            </p>
            <br />
            <p className="font-15 font-bold color-h">
              Note: 2160p images will be downloaded if
              <br /> original photos are not available
            </p>
            <br />
            <p className="font-15 font-bold color-primary">
              Are you sure you want to set Download
              <br /> preference to Original Quality?
            </p>
          </>
        )}
      </ConfirmationModal>
      <UserAgentErrorModal
        show={infoModal === INFO_MODALS.USERAGENTERROR}
        onHide={() => setInfoModal(INFO_MODALS.NONE)}
        deviceType={deviceType}
        variant={2}
      />
      {infoModal === INFO_MODALS.SUBSCRIPTION && (
        <SubscriptionModal
          isOpen={infoModal === INFO_MODALS.SUBSCRIPTION}
          onClose={() => setInfoModal(INFO_MODALS.NONE)}
          title="UPGRADE YOUR PLAN"
        />
      )}
    </div>
  );
};

const UtilizationContent = (props) => {
  return (
    <div className={props.class}>
      <div className="box" />
      <span>
        {props.title}:
        <br />
        <b>{props.value}</b>
      </span>
    </div>
  );
};

// const SelectRadioGroup = (props) => {
//   return (
//     <div className="settingRadioCard downloadSelectSize">
//       <div className="form-check me-3">
//         <input
//           className="form-check-input d-none"
//           type="radio"
//           name={props.title}
//           id={props.id1}
//           checked
//           onChange={() => {}}
//         />
//         <label className="form-check-label" htmlFor={props.id1}>
//           <div className="selectIcon">
//             <div />
//           </div>
//           <div className="unselectIcon"></div>
//           <h4>{props.select1}</h4>
//         </label>
//       </div>
//       <div className="form-check">
//         <input
//           className="form-check-input d-none"
//           type="radio"
//           name={props.title}
//           id={props.id2}
//         />
//         <label className="form-check-label" htmlFor={props.id2}>
//           <div className="selectIcon"></div>
//           <div className="unselectIcon"></div>
//           <div className="d-flex align-items-center">
//             <h4>{props.select2}</h4>
//             <UpgradeButton title="Upgrade" />
//           </div>
//         </label>
//       </div>
//     </div>
//   );
// };

export default ProfileSettings;

function validate(name, lastName) {
  const allErrors = [];
  const inputFields = [name, lastName];

  inputFields.forEach((field, index) => {
    if (field === "") {
      allErrors.push({
        field: fieldsNames[index],
        message: "This field is required",
      });
    }
  });

  if (allErrors.length === 0) {
    if (!isValidUserName(name)) {
      allErrors.push({
        field: "name",
        message: "First name must only contains characters",
      });
    }
    if (!isValidUserName(lastName)) {
      allErrors.push({
        field: "lastName",
        message: "Last name must only contains characters",
      });
    }
  }
  return allErrors;
}
