import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import LandingPageHeader from "../components/header/LandingPageHeader";
import { useDispatch } from "react-redux";
import { GET_PHOTOGRAPHER_PORTFOLIO } from "redux-store/sagas/saga-actions";
import ENV from "utils/helpers/env";

const LandingRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({
      type: GET_PHOTOGRAPHER_PORTFOLIO,
      payload: {
        id: ENV.WL_PHOTOGRAPHER_ID,
        redirect: false,
      },
    });
  }, []);

  React.useEffect(() => {
    if (!location.hash) window.scrollTo(0, 0);
  }, [location.hash, location.pathname]);

  return (
    <div className="no-overflow-x">
      {location.pathname != "/join" && location.pathname != "/" && (
        <LandingPageHeader />
      )}
      <Outlet />
    </div>
  );
};

export default LandingRoute;
