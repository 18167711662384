import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SecondarySmallBtn from "views/components/button/SecondarySmallBtn";

const PhotographerNav = () => {
  const { photographer } = useSelector((state) => state?.folderImages) || {};
  const navigate = useNavigate();

  return (
    <header className="portfolio-header py-2 d-none d-md-block">
      <nav className="container p-0 d-flex justify-content-between align-items-center">
        {photographer?.logo ? (
          <img src={photographer.logo} alt="business logo" />
        ) : (
          <p className="font-24 m-0 font-bold">{photographer?.business_name}</p>
        )}
        <ul className="p-0 m-0 font-15 d-flex justify-content-center align-items-center align-self-center gap-4 font-bold">
          <li>
            <Link to="/join">Join a Group</Link>
          </li>
          <li>
            <Link to="/auth/login">Create a Group</Link>
          </li>
          <li>
            <SecondarySmallBtn
              title="Sign Up or Login"
              className="font-15"
              onClick={() => navigate("/auth/login")}
            />
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default PhotographerNav;
