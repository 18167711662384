import { takeLatest, put, call, delay } from "redux-saga/effects";
import { AXIOS } from "../../utils/setup/axios";
import {
  setFolderLoader,
  setFolders,
  handleDeleteFolder,
  setInitialFolderLoader,
  setRenameFolder,
  setVerifyDownloadPinResponse,
  setFolderTransferSuccess,
} from "../slices/folders";
import {
  GET_FOLDERS,
  DELETE_FOLDER,
  RENAME_FOLDER,
  VERIFY_DOWNLOAD_PIN,
  CHANGE_FOLDER_ORDER,
  POST_TRANSFER_FOLDER,
} from "./saga-actions";
import { toast } from "react-toastify";

export const retryAPIDelay = 2000;
export const retryErrorMsg = "Error. Please Reload the page.";

async function getFolders(action) {
  const { groupID, includeAllPhotos, picsCount = 0 } = action;
  return AXIOS.get(`/api/app/groupFolder/folders/${groupID}`, {
    params: { includeAllPhotos, picsCount },
  });
}

async function deleteFolder(action) {
  const { folderID } = action;

  return AXIOS.post(`/api/app/groupFolder/delete/${folderID}`);
}
async function postTransferFolder(payload) {
  return AXIOS.post(`/api/app/groupFolder/transfer-folder `, payload);
}

function* getFoldersGenerator(action) {
  const { navigate } = action;
  try {
    yield put(setFolderLoader(true));
    yield put(setInitialFolderLoader(true));

    const response = yield call(getFolders, action);
    yield put(setFolders({ data: response?.data, groupID: action?.groupID }));
  } catch {
    try {
      yield delay(retryAPIDelay);
      const response = yield call(getFolders, action);
      yield put(setFolders({ data: response?.data, groupID: action?.groupID }));
    } catch (e) {
      if (e.response.status === 400 || e.response.status === 403) {
        toast.error(e.response.data?.message);
        navigate("/groups");
      }
    }
  } finally {
    yield put(setFolderLoader(false));
    yield put(setInitialFolderLoader(false));
  }
}

function* deleteFolderGenerator(action) {
  try {
    const response = yield call(deleteFolder, action);
    if (response?.data?.status === 200) {
      toast.success(response.data.message);
    }
    yield put(handleDeleteFolder(action?.folderID));
  } catch (e) {
    yield put(setFolderLoader(false));
    if (e?.response?.status === 400) {
      toast.error(e?.response?.data?.message);
    }
  }
}

async function renameFolder(action) {
  const { folderId, newPrefix } = action?.payload || {};
  return AXIOS.post(`/api/app/groupFolder/rename/${folderId}`, {
    newPrefix,
  });
}

function* renameFolderGenerator(action) {
  try {
    yield put(setFolderLoader(true));
    const response = yield call(renameFolder, action);
    if (response.status === 200) {
      toast.success(response?.data?.message);
    }
    const data = {
      response: response?.data,
      action,
    };
    yield put(setRenameFolder(data));
  } catch (e) {
    yield put(setFolderLoader(false));
    toast.error(e?.response?.data?.message);
  }
}

async function verifyDownloadPin(action) {
  const { groupId, pin } = action?.payload || {};
  return AXIOS.get(`/api/app/group/verify-download-pin/${groupId}/${pin}`);
}

function* verifyDownloadPinGenerator(action) {
  try {
    yield put(setFolderLoader(true));
    const response = yield call(verifyDownloadPin, action);

    yield put(setVerifyDownloadPinResponse(response?.data));
  } catch (e) {
    toast.error(e?.response?.data?.message);
    yield put(setFolderLoader(false));
  }
}

function changeFolderOrder(action) {
  const { groupId, order } = action?.payload || {};
  return AXIOS.post(`/api/app/groupFolder/changeFolderOrder/${groupId}`, {
    folderIds: order,
    indexToBeInsertedAt: 0,
  });
}

function* changeFolderOrderGenerator(action) {
  try {
    yield put(setFolderLoader(true));
    const response = yield call(changeFolderOrder, action);
    if (response.status === 200) {
      toast.success(response?.data?.message);
    }
  } catch (e) {
    toast.error(e?.response?.data?.message);
  }
}

function* postTransferFolderGenerator(action) {
  const { id, close, src } = action.payload;

  try {
    yield put(setFolderLoader(true));
    const response = yield call(postTransferFolder, id);
    if (response?.status === 200) {
      toast.success(response?.data?.message);
    }
    if (src === "multi-select") {
      yield put(setFolderTransferSuccess(action.payload));
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(setFolderLoader(false));
    close();
  }
}
export function* foldersSaga() {
  yield takeLatest(GET_FOLDERS, getFoldersGenerator);
  yield takeLatest(DELETE_FOLDER, deleteFolderGenerator);
  yield takeLatest(RENAME_FOLDER, renameFolderGenerator);
  yield takeLatest(VERIFY_DOWNLOAD_PIN, verifyDownloadPinGenerator);
  yield takeLatest(CHANGE_FOLDER_ORDER, changeFolderOrderGenerator);
  yield takeLatest(POST_TRANSFER_FOLDER, postTransferFolderGenerator);
}
