import React from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import css from "./css/Landing.module.css";
import IconedButton2 from "views/components/button/IconedButton2";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { ServicesOffered } from "views/photographerDashboard/PhotographerPortfolio";
import { useLocation } from "react-router-dom";
import PortfolioImages from "views/photographerDashboard/PortfolioImages";
import { CURRENCIES, SERVICES } from "views/settings/portfolio/constants";
import { getCFURL } from "utils/helpers";
import LandingPageFooter from "views/components/footer/LandingPageFooter";
import PGLandingNavbar from "./components/PGLandingNavbar";
import PoweredBy from "./components/PoweredBy";
import ENV from "utils/helpers/env";

const PhotographerLanding = () => {
  const [isPortfolio, setIsPortfolio] = React.useState({
    show: false,
    folderName: "",
  });
  const [bgImages, setBgImages] = React.useState([]);
  const servicesRef = React.useRef({
    left: null,
    right: null,
  });
  const location = useLocation();

  const { photographer, portfolio } =
    useSelector((state) => state?.folderImages) || {};

  React.useEffect(() => {
    const scrollToHash = () => {
      const el = document.querySelector(location.hash);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    };

    if (location.hash) {
      if (document.readyState === "complete") scrollToHash();
      else window.addEventListener("load", scrollToHash);
    }

    return () => {
      window.removeEventListener("load", scrollToHash);
    };
  }, [location.hash]);

  React.useEffect(() => {
    if (!portfolio) return;

    const firstImages = portfolio?.map((i) => ({
      compress: getCFURL({ url: i?.images[0]?.url }),
      thumb: getCFURL({ url: i?.images[0]?.url, pThumb: true }),
    }));

    setBgImages(firstImages);
  }, [portfolio]);

  React.useEffect(() => {
    const height = servicesRef.current.left?.clientHeight;
    if (height) {
      servicesRef.current.right.style.maxHeight = `${height}px`;
    }
  }, [photographer?.portfolioCurrency]);

  const showServices = photographer?.services?.length
    ? photographer.services.filter((i) => i.enabled).length > 0
    : false;

  return (
    <div className={css.pglanding}>
      <Container fluid>
        <Row>
          <Col className="g-0">
            <div
              className={css["cover-container"]}
              style={{
                backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.8) 100%), url(${ENV.WL_COVER_IMAGE})`,
              }}
            >
              <PGLandingNavbar />
              <div className={css.welcome}>
                <h2>{ENV.WL_LANDING_WELCOME_MESSAGE}</h2>
                <h1 className="mb-3">{ENV.WL_BUSINESS_NAME}</h1>
                <p>{ENV.WL_TAGLINE}</p>
                <IconedButton2
                  title={ENV.WL_SEE_PHOTOS_LABEL}
                  icon2="/assets/images/icons/arrow-white.png"
                  className={css["action-btn"]}
                  onClick={() => window.open(ENV.WL_SEE_PHOTOS_URL)}
                />
              </div>
              <PoweredBy className="mx-auto mb-3" variant="light" />
            </div>
          </Col>
        </Row>
        <Row id="about">
          <Col className="g-0">
            <div className={css.about}>
              <h2>About Us</h2>
              <p>
                {photographer?.aboutUs?.split("\n").map((para, i) => (
                  <React.Fragment key={i}>
                    {para}
                    <br />
                  </React.Fragment>
                ))}
              </p>
              <div className={css.socials}>
                {photographer?.insta_link_d && (
                  <a
                    href={`${photographer?.insta_link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="media-icon text-center"
                  >
                    <img src="../assets/icons/instagram_icon.png" />
                  </a>
                )}
                {photographer?.whatsApp_link_d && (
                  <a
                    href={`https://wa.me/${photographer?.whatsApp_link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="media-icon text-center"
                  >
                    <img src="../assets/icons/whatsapp.svg" />
                  </a>
                )}
                {photographer?.vimeo_link_d && (
                  <a
                    href={`${photographer?.vimeo_link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="media-icon text-center"
                  >
                    <img src="../assets/icons/v.svg" />
                  </a>
                )}
                {photographer?.fb_link_d && (
                  <a
                    href={`${photographer?.fb_link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="media-icon text-center"
                  >
                    <img src="../assets/icons/facebook.svg" />
                  </a>
                )}
                {photographer?.youtube_link_d && (
                  <a
                    href={`${photographer?.youtube_link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="media-icon text-center"
                  >
                    <img src="../assets/icons/youtube.svg" />
                  </a>
                )}
                {photographer?.business_phone_d && (
                  <a
                    href={`tel:${photographer?.business_phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="media-icon text-center"
                  >
                    <img src="../assets/icons/call.svg" />
                  </a>
                )}
                {photographer?.website_d && (
                  <a
                    href={`${photographer?.website}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="media-icon text-center"
                  >
                    <img src="../assets/icons/website.svg" />
                  </a>
                )}
                {photographer?.business_email_d && (
                  <a
                    href={`mailto:${photographer?.business_email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="media-icon text-center"
                  >
                    <img src="../assets/icons/email.svg" />
                  </a>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row id="book">
          <Col
            className={css.book}
            style={{
              backgroundImage: `linear-gradient(180deg, #0000003d 0%, #000000a3 100%), url(${ENV.WL_QUOTE_BACKGROUND})`,
            }}
          >
            <h2 className="mb-3">“{ENV.WL_QUOTE}”</h2>
            <IconedButton2
              title={ENV.WL_BOOK_NOW_LABEL}
              icon2="/assets/images/icons/arrow-white.png"
              className={css["action-btn"]}
              onClick={() => window.open(ENV.WL_BOOK_NOW_URL)}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        {showServices && (
          <Row id="services">
            <Col className={css["services-container"]}>
              <div
                className={css.services}
                ref={(e) => (servicesRef.current.left = e)}
              >
                <h2>Services Offered</h2>
                <div className="d-none d-md-flex flex-wrap gap-4">
                  <ServicesOffered
                    currency={photographer?.portfolioCurrency}
                    offerings={photographer?.services}
                    className="d-none d-md-flex flex-wrap gap-4"
                  />
                </div>
                <div className="d-block d-md-none">
                  <Slider
                    {...{
                      dots: true,
                      autoplay: true,
                      infinite: true,
                      speed: 500,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    }}
                  >
                    {photographer?.services?.length &&
                      SERVICES.map((s, i) => {
                        return {
                          ...s,
                          ...photographer?.services[i],
                        };
                      })
                        .filter((i) => i.enabled)
                        .map((i, j) => (
                          <div className="services-div mb-1" key={j}>
                            <img src={i.image} />
                            <div>
                              <p className="mb-1 base-font font-18 color-h">
                                {i?.name}
                              </p>
                              {i?.price && i?.price !== "0" && (
                                <p className="m-0 color-primary font-15 font-bold">
                                  {
                                    CURRENCIES.find(
                                      (c) =>
                                        c.name ==
                                        photographer?.portfolioCurrency
                                    )?.symbol
                                  }
                                  &nbsp;
                                  {i.price}
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                  </Slider>
                </div>
              </div>
              <div ref={(e) => (servicesRef.current.right = e)}>
                <img src={ENV.WL_SERVICES_BACKGROUND} alt="placeholder" />
              </div>
            </Col>
          </Row>
        )}

        {portfolio?.length > 0 && (
          <Row id="portfolio" className={css.portfolio}>
            <Col xs={12}>
              <h2>Portfolio</h2>
            </Col>
            {portfolio?.map((items, i) => (
              <Col key={i} className="portfolio-container" md={6}>
                <div
                  className="portfolio"
                  onClick={() => {
                    setIsPortfolio({
                      show: true,
                      folderName: items?.folder,
                    });
                  }}
                >
                  <img
                    src={bgImages[i]?.thumb}
                    className="position-absolute top-0 bottom-0 start-0 end-0 w-100 h-100 rounded-3 object-cover"
                    onError={(e) => {
                      e.target.src = bgImages[i]?.compress;
                    }}
                  />
                  <p>{items?.folder}</p>
                  <div className="portfolio-icon">
                    <img src="/assets/icons/right_arrow.svg" />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </Container>
      {!isPortfolio?.folderName && <LandingPageFooter />}
      <Modal show={isPortfolio?.show} fullscreen className="bg-white">
        <PortfolioImages
          folderName={isPortfolio?.folderName}
          closePortfolio={() => setIsPortfolio({ show: false, folderName: "" })}
        />
      </Modal>
    </div>
  );
};

export default PhotographerLanding;
