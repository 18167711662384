import React, { useEffect, useState } from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import DefaultGallaryHeading from "../../gallary/components/DefaultGallaryHeading";
import UserAgentErrorModal from "../modals/UserAgentModal";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import GallaryStyle4 from "../../gallary/components/GallaryStyle4";
// import { GET_GROUP_DETAILS_AND_OTHER_SETTINGS } from "redux-store/sagas/saga-actions";
import { /*useDispatch,*/ useDispatch, useSelector } from "react-redux";
import FocusedImage from "../../settings/components/focused-image";
import MenuDrawer from "../sidebar/MenuDrawer";
import Spinner from "../loader/Spinner";
import useLogoutHandler from "hooks/useLogoutHandler";
import cx from "classnames";
import useDetectUserAgent from "hooks/useDetectUserAgent";
import {
  SEND_USER_CLICK_ANALYTICS,
  GET_GROUP_ADMIN_TOKEN,
  GET_USER_DETAILS,
} from "redux-store/sagas/saga-actions";
import useSubscription, {
  PLAN_FEATURES,
} from "views/subscription/hooks/useSubscription";
import SubscriptionModal from "views/subscription";
import ENV, { currentUserIsOwner } from "utils/helpers/env";
import GroupShareModal from "../modals/GroupShareModal";
import { handleDownloadRouter } from "utils/helpers/downloads";
import Sponsors from "views/gallary/components/Sponsors";
import { toast } from "react-toastify";
import Notifications from "./Notifications";
import useSize from "hooks/useSize";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import ANALYTICS from "constants/analyticsKeys";
import ExpandableButton from "../button/ExpandableButton";

const GallaryHeader = ({ goBack, template }) => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const { groupDetails, groupSettingLoader, brandingData } = useSelector(
    (state) => state.settings
  );
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const navigate = useNavigate();

  const { hasAccessToFeature, getMySubscriptionPlanAPI } = useSubscription();

  const localData = getLocalStorageUser();
  let avatar = localData?.avatar;
  let user = useSelector((state) => state.user.user);
  if (!user?.name) {
    user = localData;
  }
  if (!user?.avatar)
    user = {
      ...user,
      avatar,
    };

  const backURL = typeof goBack === "string" ? goBack : `/groups`;

  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (!localData?.name) dispatch({ type: GET_USER_DETAILS });

    getMySubscriptionPlanAPI();
  }, []);

  useEffect(() => {
    if (groupDetails) {
      setLoading(false);
    }
  }, [groupDetails]);

  const hasAccess = hasAccessToFeature(PLAN_FEATURES.view_analytics);

  if (groupSettingLoader) {
    return <Spinner loading={loading} />;
  }

  const handleAnalyticsNav = (e) => {
    e.preventDefault();
    if (hasAccess) {
      navigate("/analytics");
    } else if (currentUserIsOwner()) {
      setSubscriptionModal(true);
    } else {
      toast.error("You don't have access to this feature. Please subscribe.");
    }
    dispatch({
      type: SEND_USER_CLICK_ANALYTICS,
      payload: { buttons: [ANALYTICS.ANALYTICS] },
    });
  };

  const { aboutUs, website_route, coverImage } = brandingData || {};
  const showPortfolio = !!website_route && !!aboutUs && !!coverImage;

  return (
    <div
      className={cx(
        "defaultHeader",
        template ? "limit-in-100vh" : "without-banner-header"
      )}
    >
      {!template && (
        <WithoutBanner
          goBack={backURL}
          user={user}
          params={params}
          group={groupDetails}
          branding={brandingData}
          handleAnalyticsNav={handleAnalyticsNav}
          showPortfolio={showPortfolio}
        />
      )}

      {/*{props.afterScroll && (*/}
      {/*  <AfterScroll setUploadImage={props.setUploadImage} goBack={props.goBack} params={params} />*/}
      {/*)}*/}
      {template === 1 && (
        <Template1
          goBack={backURL}
          params={params}
          user={user}
          group={groupDetails}
          branding={brandingData}
          handleAnalyticsNav={handleAnalyticsNav}
          showPortfolio={showPortfolio}
        />
      )}
      {template === 2 && (
        <Template2
          user={user}
          goBack={backURL}
          params={params}
          group={groupDetails}
          branding={brandingData}
          handleAnalyticsNav={handleAnalyticsNav}
          showPortfolio={showPortfolio}
        />
      )}
      {template === 3 && (
        <Template3
          user={user}
          goBack={backURL}
          params={params}
          group={groupDetails}
          branding={brandingData}
          handleAnalyticsNav={handleAnalyticsNav}
          showPortfolio={showPortfolio}
        />
      )}
      {template === 4 && (
        <Template4
          user={user}
          goBack={backURL}
          params={params}
          group={groupDetails}
          branding={brandingData}
          handleAnalyticsNav={handleAnalyticsNav}
          showPortfolio={showPortfolio}
        />
      )}
      {template === 5 && (
        <Template5
          user={user}
          goBack={backURL}
          params={params}
          group={groupDetails}
          branding={brandingData}
          handleAnalyticsNav={handleAnalyticsNav}
          showPortfolio={showPortfolio}
        />
      )}
      {subscriptionModal && (
        <SubscriptionModal
          isOpen={subscriptionModal}
          onClose={() => setSubscriptionModal(false)}
        />
      )}
    </div>
  );
};

const WithoutBanner = (props) => {
  const { logoutHandler } = useLogoutHandler();

  const isPhotographer = props?.user?.userType === "PHOTOGRAPHER";
  const dispatch = useDispatch();
  const userName =
    (props?.user?.name || "") + " " + (props?.user?.lastName || "");

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container className="mw-100">
          {props.goBack && (
            <Navbar.Brand
              as={Link}
              to={props.goBack}
              className="back-button-wrapper"
            >
              <img
                width="16px"
                src="/assets/images/icons/back-arrow.png"
                alt="logo"
                className="darkBack back-button"
              />
              <img
                width="16px"
                src="/assets/images/icons/back-white.png"
                alt="logo"
                className="lightBack back-button"
              />
            </Navbar.Brand>
          )}
          <Navbar.Brand as={Link} to="/groups" className="p-0">
            <img
              src={ENV.WL_PRIMARY_LOGO}
              alt="logo"
              className="logo logo-dark"
            />
            <img
              src={ENV.WL_WHITE_LOGO}
              alt="logo"
              className="logo logo-white"
            />
          </Navbar.Brand>

          <Notifications className="ms-auto" />
          <MenuDrawer handleAnalyticsNav={props.handleAnalyticsNav} />
          <Navbar.Collapse id="responsive-navbar-nav" className="flex-grow-0">
            <Nav>
              <NavDropdown
                align="end"
                title={
                  <div className="header-user bold-font">
                    <div className="header-thumb">
                      <img
                        className="thumbnail-image"
                        src={
                          props?.user?.avatar || "/assets/images/icons/user.png"
                        }
                        alt="user pic"
                      />
                    </div>
                    {userName}
                    <img
                      className="menu-arrow"
                      src="/assets/images/icons/arrow-down.png"
                      alt="user pic"
                    />
                  </div>
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  onClick={() => {
                    isPhotographer &&
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.BUSINESS_SETTINGS] },
                      });
                  }}
                  to="/profile-settings/profile"
                >
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/setting.png"
                    alt="user pic"
                  />
                  {isPhotographer ? "Business Settings" : "Profile Settings"}
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  onClick={props.handleAnalyticsNav}
                >
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/level.png"
                  />
                  Analytics
                </NavDropdown.Item>
                {!ENV.WL_HIDE_HELPDESK && (
                  <NavDropdown.Item
                    href="https://www.kwikpic.in/helpdesk"
                    target="_blank"
                    onClick={() => {
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.HELP_SUPPORT] },
                      });
                    }}
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/infoq.png"
                      alt="user pic"
                    />
                    Help & Support
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/privacy-security">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/privacy-security/privacy.png"
                    alt="security icon"
                  />
                  Privacy and Security
                </NavDropdown.Item>
                {!ENV.WL_HIDE_TUTORIAL && (
                  <NavDropdown.Item
                    href={
                      props?.user?.userType === "USER"
                        ? "https://youtube.com/playlist?list=PLO8szijcLbfVNHoLFKandmqtstv7KkxTu"
                        : "https://youtube.com/playlist?list=PLO8szijcLbfUq-K2d-f3aGggauF1w-T-p"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.TUTORIAL] },
                      })
                    }
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/youtube-tut.png"
                      alt="tutorial"
                    />
                    Tutorial
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/#about">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/info.png"
                    alt="user pic"
                  />
                  About
                </NavDropdown.Item>
                <NavDropdown.Item onClick={logoutHandler}>
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/download.png"
                    alt="user pic"
                  />
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <DefaultGallaryHeading
        title={props?.group?.name || ""}
        group={props?.group}
        branding={props?.branding}
        showPortfolio={props?.showPortfolio}
      />
    </>
  );
};

export const Template1 = (props) => {
  const { generalSettings } = useSelector((state) => state.settings);
  const [focus, setFocus] = useState(null);
  const { logoutHandler } = useLogoutHandler();
  const dispatch = useDispatch();
  const { width } = useSize();

  useEffect(() => {
    if (generalSettings.iconFocalPoint != undefined) {
      setFocus(generalSettings.iconFocalPoint.split("/"));
    }
  }, [generalSettings]);

  const userName =
    (props?.user?.name || "") + " " + (props?.user?.lastName || "");
  const isPhotographer = props?.user?.userType === "PHOTOGRAPHER";

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        className="overRideHeader header1"
      >
        <Container className="mw-100">
          {props.goBack && (
            <Navbar.Brand
              as={Link}
              to={props.goBack}
              className="back-button-wrapper"
            >
              <img
                width="16px"
                src="/assets/images/icons/back-white.png"
                alt="logo"
                className="darkBack back-button"
              />
              <img
                width="16px"
                src="/assets/images/icons/back-white.png"
                alt="logo"
                className="lightBack back-button"
              />
            </Navbar.Brand>
          )}
          <Navbar.Brand as={Link} to="/groups" className="p-0">
            <img src={ENV.WL_WHITE_LOGO} alt="logo" className="logo" />
          </Navbar.Brand>

          <Notifications className="ms-auto" />
          <MenuDrawer
            alwaysWhite={true}
            handleAnalyticsNav={props.handleAnalyticsNav}
          />
          <Navbar.Collapse id="responsive-navbar-nav" className="flex-grow-0">
            <Nav>
              <NavDropdown
                align="end"
                title={
                  <div className="header-user bold-font">
                    <div className="header-thumb">
                      <img
                        className="thumbnail-image"
                        src={
                          props?.user?.avatar || "/assets/images/icons/user.png"
                        }
                        alt="user pic"
                      />
                    </div>
                    {userName}
                    <img
                      className="menu-arrow"
                      src="/assets/images/icons/arrow-down.png"
                      alt="user pic"
                    />
                  </div>
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  onClick={() => {
                    isPhotographer &&
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.BUSINESS_SETTINGS] },
                      });
                  }}
                  to="/profile-settings/profile"
                >
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/setting.png"
                    alt="user pic"
                  />
                  {isPhotographer ? "Business Settings" : "Profile Settings"}
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  onClick={props.handleAnalyticsNav}
                >
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/level.png"
                  />
                  Analytics
                </NavDropdown.Item>
                {!ENV.WL_HIDE_HELPDESK && (
                  <NavDropdown.Item
                    href="https://www.kwikpic.in/helpdesk"
                    target="_blank"
                    onClick={() => {
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.HELP_SUPPORT] },
                      });
                    }}
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/infoq.png"
                      alt="user pic"
                    />
                    Help & Support
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/privacy-security">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/privacy-security/privacy.png"
                    alt="security icon"
                  />
                  Privacy and Security
                </NavDropdown.Item>
                {!ENV.WL_HIDE_TUTORIAL && (
                  <NavDropdown.Item
                    href={
                      props?.user?.userType === "USER"
                        ? "https://youtube.com/playlist?list=PLO8szijcLbfVNHoLFKandmqtstv7KkxTu"
                        : "https://youtube.com/playlist?list=PLO8szijcLbfUq-K2d-f3aGggauF1w-T-p"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.TUTORIAL] },
                      })
                    }
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/youtube-tut.png"
                      alt="tutorial"
                    />
                    Tutorial
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/#about">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/info.png"
                    alt="user pic"
                  />
                  About
                </NavDropdown.Item>
                <NavDropdown.Item onClick={logoutHandler}>
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/download.png"
                    alt="user pic"
                  />
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="temp-gallary-image temp1-gallary-image overflow-hidden">
        {focus ? (
          width > 576 ? (
            <FocusedImage
              imageSrc={generalSettings.coverIcon || generalSettings.icon}
              x={Number(focus[0])}
              y={Number(focus[1])}
            />
          ) : (
            <FocusedImage
              imageSrc={
                generalSettings.coverIcon?.replace(
                  "cover-icons",
                  "mobile-cover-icons"
                ) ||
                generalSettings.icon?.replace(
                  "small-icons",
                  "mobile-cover-icons"
                ) ||
                ""
              }
              x={Number(focus[0]) || 0.0}
              y={Number(focus[1]) || 0.0}
              onError={(e) => {
                e.target.src =
                  generalSettings.coverIcon || generalSettings.icon;
              }}
            />
          )
        ) : null}

        <div
          className="actionArrow"
          onClick={() =>
            document
              .getElementById("gallery-folders-list")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          <img
            className="banner-image cursor-pointer"
            src="/assets/images/icons/Group-down.png"
          />
        </div>
      </div>
      <DefaultGallaryHeading
        title={generalSettings.groupName}
        group={props?.group}
        branding={props.branding}
        showPortfolio={props?.showPortfolio}
      />
    </>
  );
};

const Template2 = (props) => {
  const { generalSettings } = useSelector((state) => state.settings);
  const [focus, setFocus] = useState(null);
  const dispatch = useDispatch();
  const { logoutHandler } = useLogoutHandler();
  const { width } = useSize();

  useEffect(() => {
    if (generalSettings.iconFocalPoint != undefined) {
      setFocus(generalSettings.iconFocalPoint.split("/"));
    }
  }, [generalSettings]);

  const userName =
    (props?.user?.name || "") + " " + (props?.user?.lastName || "");
  const isPhotographer = props?.user?.userType === "PHOTOGRAPHER";

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        className="overRideHeader temp3-header"
      >
        <Container className="mw-100">
          {props.goBack && (
            <Navbar.Brand
              as={Link}
              to={props.goBack}
              className="back-button-wrapper"
            >
              <img
                width="16px"
                src="/assets/images/icons/back-arrow.png"
                alt="logo"
                className="darkBack back-button"
              />
              <img
                width="16px"
                src="/assets/images/icons/back-white.png"
                alt="logo"
                className="lightBack back-button"
              />
            </Navbar.Brand>
          )}
          <Navbar.Brand as={Link} to="/groups" className="p-0">
            <img
              src={ENV.WL_PRIMARY_LOGO}
              alt="logo"
              className="darkBack logo"
            />
            <img
              src={ENV.WL_COLORED_LOGO}
              alt="logo"
              className="lightBack logo"
            />
          </Navbar.Brand>

          <Notifications className="ms-auto" />
          <MenuDrawer handleAnalyticsNav={props.handleAnalyticsNav} />
          <Navbar.Collapse id="responsive-navbar-nav" className="flex-grow-0">
            <Nav>
              <NavDropdown
                align="end"
                title={
                  <div className="header-user bold-font">
                    <div className="header-thumb">
                      <img
                        className="thumbnail-image"
                        src={
                          props?.user?.avatar || "/assets/images/icons/user.png"
                        }
                        alt="user pic"
                      />
                    </div>
                    {userName}
                    <img
                      className="menu-arrow"
                      src="/assets/images/icons/arrow-down.png"
                      alt="user pic"
                    />
                  </div>
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  onClick={() => {
                    isPhotographer &&
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.BUSINESS_SETTINGS] },
                      });
                  }}
                  to="/profile-settings/profile"
                >
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/setting.png"
                    alt="user pic"
                  />
                  {isPhotographer ? "Business Settings" : "Profile Settings"}
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  onClick={props.handleAnalyticsNav}
                >
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/level.png"
                  />
                  Analytics
                </NavDropdown.Item>
                {!ENV.WL_HIDE_HELPDESK && (
                  <NavDropdown.Item
                    href="https://www.kwikpic.in/helpdesk"
                    target="_blank"
                    onClick={() => {
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.HELP_SUPPORT] },
                      });
                    }}
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/infoq.png"
                      alt="user pic"
                    />
                    Help & Support
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/privacy-security">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/privacy-security/privacy.png"
                    alt="security icon"
                  />
                  Privacy and Security
                </NavDropdown.Item>
                {!ENV.WL_HIDE_TUTORIAL && (
                  <NavDropdown.Item
                    href={
                      props?.user?.userType === "USER"
                        ? "https://youtube.com/playlist?list=PLO8szijcLbfVNHoLFKandmqtstv7KkxTu"
                        : "https://youtube.com/playlist?list=PLO8szijcLbfUq-K2d-f3aGggauF1w-T-p"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.TUTORIAL] },
                      })
                    }
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/youtube-tut.png"
                      alt="tutorial"
                    />
                    Tutorial
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/#about">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/info.png"
                    alt="user pic"
                  />
                  About
                </NavDropdown.Item>
                <NavDropdown.Item onClick={logoutHandler}>
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/download.png"
                    alt="user pic"
                  />
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="temp-gallary-image temp3-gallary-image">
        <div className="left-bar-temp3"></div>
        <div className="right-bar-temp3"></div>
        <div className="bottom-bar-temp3"></div>
        {focus ? (
          width > 576 ? (
            <FocusedImage
              imageSrc={generalSettings.coverIcon || generalSettings.icon}
              x={Number(focus[0])}
              y={Number(focus[1])}
            />
          ) : (
            <FocusedImage
              imageSrc={
                generalSettings.coverIcon?.replace(
                  "cover-icons",
                  "mobile-cover-icons"
                ) ||
                generalSettings.icon?.replace(
                  "small-icons",
                  "mobile-cover-icons"
                ) ||
                ""
              }
              x={Number(focus[0]) || 0.0}
              y={Number(focus[1]) || 0.0}
              onError={(e) => {
                e.target.src =
                  generalSettings.coverIcon || generalSettings.icon;
              }}
            />
          )
        ) : null}
        <DefaultGallaryHeading
          title={props?.group?.name || ""}
          group={props?.group}
          branding={props?.branding}
          template2={true}
          showPortfolio={props?.showPortfolio}
        />
      </div>
    </>
  );
};

const Template3 = (props) => {
  const { generalSettings } = useSelector((state) => state.settings);
  const [focus, setFocus] = useState(null);
  const dispatch = useDispatch();
  const { logoutHandler } = useLogoutHandler();
  const { width } = useSize();

  useEffect(() => {
    if (generalSettings.iconFocalPoint != undefined) {
      setFocus(generalSettings.iconFocalPoint.split("/"));
    }
  }, [generalSettings]);

  const userName =
    (props?.user?.name || "") + " " + (props?.user?.lastName || "");
  const isPhotographer = props?.user?.userType === "PHOTOGRAPHER";

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        className="overRideHeader header1"
      >
        <Container className="mw-100">
          {props.goBack && (
            <Navbar.Brand
              as={Link}
              to={props.goBack}
              className="back-button-wrapper"
            >
              <img
                width="16px"
                src="/assets/images/icons/back-white.png"
                alt="logo"
                className="back-button"
              />
            </Navbar.Brand>
          )}
          <Navbar.Brand as={Link} to="/groups" className="p-0">
            <img src={ENV.WL_WHITE_LOGO} alt="logo" className="logo" />
          </Navbar.Brand>

          <Notifications className="ms-auto" />
          <MenuDrawer
            alwaysWhite={true}
            handleAnalyticsNav={props.handleAnalyticsNav}
          />
          <Navbar.Collapse id="responsive-navbar-nav" className="flex-grow-0">
            <Nav>
              <NavDropdown
                align="end"
                title={
                  <div className="header-user bold-font">
                    <div className="header-thumb">
                      <img
                        className="thumbnail-image"
                        src={
                          props?.user?.avatar || "/assets/images/icons/user.png"
                        }
                        alt="user pic"
                      />
                    </div>
                    {userName}
                    <img
                      className="menu-arrow"
                      src="/assets/images/icons/arrow-down.png"
                      alt="user pic"
                    />
                  </div>
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  onClick={() => {
                    isPhotographer &&
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.BUSINESS_SETTINGS] },
                      });
                  }}
                  to="/profile-settings/profile"
                >
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/setting.png"
                    alt="user pic"
                  />
                  {isPhotographer ? "Business Settings" : "Profile Settings"}
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  onClick={props.handleAnalyticsNav}
                >
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/level.png"
                  />
                  Analytics
                </NavDropdown.Item>
                {!ENV.WL_HIDE_HELPDESK && (
                  <NavDropdown.Item
                    href="https://www.kwikpic.in/helpdesk"
                    target="_blank"
                    onClick={() => {
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.HELP_SUPPORT] },
                      });
                    }}
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/infoq.png"
                      alt="user pic"
                    />
                    Help & Support
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/privacy-security">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/privacy-security/privacy.png"
                    alt="security icon"
                  />
                  Privacy and Security
                </NavDropdown.Item>
                {!ENV.WL_HIDE_TUTORIAL && (
                  <NavDropdown.Item
                    href={
                      props?.user?.userType === "USER"
                        ? "https://youtube.com/playlist?list=PLO8szijcLbfVNHoLFKandmqtstv7KkxTu"
                        : "https://youtube.com/playlist?list=PLO8szijcLbfUq-K2d-f3aGggauF1w-T-p"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.TUTORIAL] },
                      })
                    }
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/youtube-tut.png"
                      alt="tutorial"
                    />
                    Tutorial
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/#about">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/info.png"
                    alt="user pic"
                  />
                  About
                </NavDropdown.Item>
                <NavDropdown.Item onClick={logoutHandler}>
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/download.png"
                    alt="user pic"
                  />
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="temp-gallary-image temp5-gallary-image">
        {focus ? (
          width > 576 ? (
            <FocusedImage
              imageSrc={generalSettings.coverIcon || generalSettings.icon}
              x={Number(focus[0])}
              y={Number(focus[1])}
            />
          ) : (
            <FocusedImage
              imageSrc={
                generalSettings.coverIcon?.replace(
                  "cover-icons",
                  "mobile-cover-icons"
                ) ||
                generalSettings.icon?.replace(
                  "small-icons",
                  "mobile-cover-icons"
                ) ||
                ""
              }
              x={Number(focus[0]) || 0.0}
              y={Number(focus[1]) || 0.0}
              onError={(e) => {
                e.target.src =
                  generalSettings.coverIcon || generalSettings.icon;
              }}
            />
          )
        ) : null}
        <DefaultGallaryHeading
          title={props?.group?.name || ""}
          group={props?.group}
          branding={props?.branding}
          showPortfolio={props?.showPortfolio}
        />
        <img
          className="down-arrow cursor-pointer"
          src="/assets/images/icons/down-arrow-white.png"
          alt="down-arrow-white"
          onClick={() =>
            document
              .getElementById("gallery-folders-list")
              .scrollIntoView({ behavior: "smooth" })
          }
        />
      </div>
    </>
  );
};

const Template4 = (props) => {
  const { generalSettings } = useSelector((state) => state.settings);
  const [focus, setFocus] = useState(null);
  const dispatch = useDispatch();
  const { logoutHandler } = useLogoutHandler();
  const { width } = useSize();

  useEffect(() => {
    if (generalSettings.iconFocalPoint != undefined) {
      setFocus(generalSettings.iconFocalPoint.split("/"));
    }
  }, [generalSettings]);

  const userName =
    (props?.user?.name || "") + " " + (props?.user?.lastName || "");
  const isPhotographer = props?.user?.userType === "PHOTOGRAPHER";

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        className="overRideHeader pt-2 header1"
      >
        <Container className="justify-content-start align-items-center mw-100">
          {props.goBack && (
            <Navbar.Brand
              className="back-button-wrapper"
              as={Link}
              to={props.goBack}
            >
              <img
                width="16px"
                src="/assets/images/icons/back-white.png"
                alt="logo"
                className="back-button"
              />
            </Navbar.Brand>
          )}

          <Navbar.Brand as={Link} to="/groups">
            <img src={ENV.WL_WHITE_LOGO} alt="logo" className="logo" />
          </Navbar.Brand>

          <Notifications className="ms-auto" />
          <MenuDrawer
            alwaysWhite={true}
            handleAnalyticsNav={props.handleAnalyticsNav}
          />
          <Navbar.Collapse id="responsive-navbar-nav" className="flex-grow-0">
            <Nav>
              <NavDropdown
                align="end"
                title={
                  <div className="header-user bold-font">
                    <div className="header-thumb">
                      <img
                        className="thumbnail-image"
                        src={
                          props?.user?.avatar || "/assets/images/icons/user.png"
                        }
                        alt="user pic"
                      />
                    </div>
                    {userName}
                    <img
                      className="menu-arrow"
                      src="/assets/images/icons/arrow-down.png"
                      alt="user pic"
                    />
                  </div>
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  onClick={() => {
                    isPhotographer &&
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.BUSINESS_SETTINGS] },
                      });
                  }}
                  to="/profile-settings/profile"
                >
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/setting.png"
                    alt="user pic"
                  />
                  {isPhotographer ? "Business Settings" : "Profile Settings"}
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  onClick={props.handleAnalyticsNav}
                >
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/level.png"
                  />
                  Analytics
                </NavDropdown.Item>
                {!ENV.WL_HIDE_HELPDESK && (
                  <NavDropdown.Item
                    href="https://www.kwikpic.in/helpdesk"
                    target="_blank"
                    onClick={() => {
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.HELP_SUPPORT] },
                      });
                    }}
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/infoq.png"
                      alt="user pic"
                    />
                    Help & Support
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/privacy-security">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/privacy-security/privacy.png"
                    alt="security icon"
                  />
                  Privacy and Security
                </NavDropdown.Item>
                {!ENV.WL_HIDE_TUTORIAL && (
                  <NavDropdown.Item
                    href={
                      props?.user?.userType === "USER"
                        ? "https://youtube.com/playlist?list=PLO8szijcLbfVNHoLFKandmqtstv7KkxTu"
                        : "https://youtube.com/playlist?list=PLO8szijcLbfUq-K2d-f3aGggauF1w-T-p"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.TUTORIAL] },
                      })
                    }
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/youtube-tut.png"
                      alt="tutorial"
                    />
                    Tutorial
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/#about">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/info.png"
                    alt="user pic"
                  />
                  About
                </NavDropdown.Item>
                <NavDropdown.Item onClick={logoutHandler}>
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/download.png"
                    alt="user pic"
                  />
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="temp-gallary-image temp4-gallary-image">
        {focus ? (
          width > 576 ? (
            <FocusedImage
              imageSrc={generalSettings.coverIcon || generalSettings.icon}
              x={Number(focus[0])}
              y={Number(focus[1])}
            />
          ) : (
            <FocusedImage
              imageSrc={
                generalSettings.coverIcon?.replace(
                  "cover-icons",
                  "mobile-cover-icons"
                ) ||
                generalSettings.icon?.replace(
                  "small-icons",
                  "mobile-cover-icons"
                ) ||
                ""
              }
              x={Number(focus[0]) || 0.0}
              y={Number(focus[1]) || 0.0}
              onError={(e) => {
                e.target.src =
                  generalSettings.coverIcon || generalSettings.icon;
              }}
            />
          )
        ) : null}

        <GallaryStyle4
          branding={props?.branding}
          title={props?.group?.name || ""}
          group={props?.group}
          showPortfolio={props?.showPortfolio}
        />
      </div>
    </>
  );
};

const Template5 = (props) => {
  const [deviceType, setDeviceType] = useState({ type: "desktop" });
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [focus, setFocus] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [params] = useSearchParams();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { logoutHandler } = useLogoutHandler();
  const { width } = useSize();
  useDetectUserAgent(setDeviceType);

  const {
    generalSettings,
    leaveParticipantSettings,
    downloadSettings,
    groupDetails = {},
  } = useSelector((state) => state.settings);

  useEffect(() => {
    if (generalSettings.iconFocalPoint != undefined) {
      setFocus(generalSettings.iconFocalPoint.split("/"));
    }
  }, [generalSettings]);

  const downloadPerms = {
    isAdmin: leaveParticipantSettings?.isAdmin,
    allowDownload: generalSettings?.allowDownload,
    bulkDownload: downloadSettings?.bulkDownload,
  };

  const handleSetModal = () => {
    if (groupDetails) {
      setModalShow(true);
      if (downloadPerms.isAdmin && !groupDetails?.isPrivate) {
        dispatch({
          type: GET_GROUP_ADMIN_TOKEN,
          groupCode: groupDetails.groupCode,
        });
      }
    }
  };

  const groupID = params.get("groupId");
  const userName =
    (props?.user?.name || "") + " " + (props?.user?.lastName || "");
  const isPhotographer = props?.user?.userType === "PHOTOGRAPHER";

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="transparent"
        variant="light"
        className="overRideHeader temp2-nav"
      >
        <Container className="d-flex mw-100">
          {props.goBack && (
            <Navbar.Brand
              as={Link}
              to={props.goBack}
              className="back-button-wrapper"
            >
              <img
                width="16px"
                src="/assets/images/icons/back-arrow.png"
                alt="logo"
                className="darkBack back-button"
              />
              <img
                width="16px"
                src="/assets/images/icons/back-white.png"
                alt="logo"
                className="lightBack back-button"
              />
            </Navbar.Brand>
          )}

          <Navbar.Brand as={Link} to="/groups">
            <img
              src={ENV.WL_PRIMARY_LOGO}
              alt="logo"
              className="darkBack logo"
            />
            <img
              src={ENV.WL_COLORED_LOGO}
              alt="logo"
              className="lightBack logo"
            />
          </Navbar.Brand>

          <Notifications className="ms-auto" />
          <MenuDrawer handleAnalyticsNav={props.handleAnalyticsNav} />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="flex-grow-0 h-40"
          >
            <Nav>
              <NavDropdown
                align="end"
                title={
                  <div className="header-user bold-font">
                    <div className="header-thumb">
                      <img
                        className="thumbnail-image"
                        src={
                          props?.user?.avatar || "/assets/images/icons/user.png"
                        }
                        alt="user pic"
                      />
                    </div>
                    {userName}
                    <img
                      className="menu-arrow"
                      src="/assets/images/icons/arrow-down.png"
                      alt="user pic"
                    />
                  </div>
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  onClick={() => {
                    isPhotographer &&
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.BUSINESS_SETTINGS] },
                      });
                  }}
                  to="/profile-settings/profile"
                >
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/setting.png"
                    alt="user pic"
                  />
                  {isPhotographer ? "Business Settings" : "Profile Settings"}
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  onClick={props.handleAnalyticsNav}
                >
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/level.png"
                  />
                  Analytics
                </NavDropdown.Item>
                {!ENV.WL_HIDE_HELPDESK && (
                  <NavDropdown.Item
                    href="https://www.kwikpic.in/helpdesk"
                    target="_blank"
                    onClick={() => {
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.HELP_SUPPORT] },
                      });
                    }}
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/infoq.png"
                      alt="user pic"
                    />
                    Help & Support
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/privacy-security">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/privacy-security/privacy.png"
                    alt="security icon"
                  />
                  Privacy and Security
                </NavDropdown.Item>
                {!ENV.WL_HIDE_TUTORIAL && (
                  <NavDropdown.Item
                    href={
                      props?.user?.userType === "USER"
                        ? "https://youtube.com/playlist?list=PLO8szijcLbfVNHoLFKandmqtstv7KkxTu"
                        : "https://youtube.com/playlist?list=PLO8szijcLbfUq-K2d-f3aGggauF1w-T-p"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      dispatch({
                        type: SEND_USER_CLICK_ANALYTICS,
                        payload: { buttons: [ANALYTICS.TUTORIAL] },
                      })
                    }
                  >
                    <img
                      className="thumbnail-image"
                      src="/assets/images/icons/youtube-tut.png"
                      alt="tutorial"
                    />
                    Tutorial
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/#about">
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/info.png"
                    alt="user pic"
                  />
                  About
                </NavDropdown.Item>
                <NavDropdown.Item onClick={logoutHandler}>
                  <img
                    className="thumbnail-image"
                    src="/assets/images/icons/download.png"
                    alt="user pic"
                  />
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="temp-gallary-image temp2-gallary-image">
        <Sponsors
          branding={props?.branding}
          isAnonymous={props?.isAnonymous}
          showPortfolio={props?.showPortfolio}
          template="3"
        />
        {focus ? (
          <div className="banner-image">
            {width > 576 ? (
              <FocusedImage
                imageSrc={generalSettings.coverIcon || generalSettings.icon}
                x={Number(focus[0])}
                y={Number(focus[1])}
              />
            ) : (
              <FocusedImage
                imageSrc={
                  generalSettings.coverIcon?.replace(
                    "cover-icons",
                    "mobile-cover-icons"
                  ) ||
                  generalSettings.icon?.replace(
                    "small-icons",
                    "mobile-cover-icons"
                  ) ||
                  ""
                }
                x={Number(focus[0]) || 0.0}
                y={Number(focus[1]) || 0.0}
                onError={(e) => {
                  e.target.src =
                    generalSettings.coverIcon || generalSettings.icon;
                }}
              />
            )}
          </div>
        ) : null}
        <div className="gallaryHeadingLeft d-flex flex-column justify-content-center align-items-center mt-4">
          <h2 className="font-bold m-0">{props?.group?.name || ""}</h2>
          <span className="font-bold font-12">
            {props?.group?.allPicsCount || 0} Photos
          </span>
          <div className="p-1 d-flex gap-2">
            <ExpandableButton
              className="GalleryHeadingLeftSetting d-flex d-md-none"
              icon="/assets/images/icons/setting.png"
              title="Settings"
              to={`/settings/list?groupId=${groupID}`}
              onClick={() => {
                dispatch({
                  type: SEND_USER_CLICK_ANALYTICS,
                  payload: { buttons: [ANALYTICS.SETTINGS] },
                });
              }}
            />
            <ExpandableButton
              className="GalleryHeadingLeftSetting d-none d-md-flex"
              icon="/assets/images/icons/setting.png"
              title="Settings"
              to={`/settings/general?groupId=${groupID}`}
              onClick={() => {
                dispatch({
                  type: SEND_USER_CLICK_ANALYTICS,
                  payload: { buttons: [ANALYTICS.SETTINGS] },
                });
              }}
            />
            <ExpandableButton
              className="GalleryHeadingLeftShare"
              icon="/assets/images/icons/share.png"
              title="Share"
              onClick={() => {
                handleSetModal();
                dispatch({
                  type: SEND_USER_CLICK_ANALYTICS,
                  payload: { buttons: [ANALYTICS.SHARE] },
                });
              }}
            />
            <ExpandableButton
              className="GalleryHeadingLeftDownload"
              icon="/assets/images/icons/download.png"
              title="Download&nbsp;All"
              onClick={() => {
                dispatch({
                  type: SEND_USER_CLICK_ANALYTICS,
                  payload: { buttons: [ANALYTICS.DOWNLOAD] },
                });
                handleDownloadRouter(
                  groupID,
                  navigate,
                  deviceType,
                  setShowErrorModal,
                  downloadPerms
                );
              }}
            />
            {groupDetails.folders?.length > 10 && (
              <ExpandableButton
                className="GalleryHeadingLeftFolders"
                titleClassName="text-nowrap"
                {...(pathname === "/gallery/folders"
                  ? {
                      icon: "/assets/images/icons/gallery/image.png",
                      to: `/gallery?groupId=${groupID}`,
                      title: "Image View",
                    }
                  : {
                      icon: "/assets/images/icons/folder2.png",
                      to: `/gallery/folders?groupId=${groupID}`,
                      title: "Folder view",
                    })}
              />
            )}
          </div>
          <img
            className="mt-md-2 mt-5 cursor-pointer"
            src="/assets/images/icons/arrow-down-gray.svg"
            height={20}
            alt="arrow-down-gray"
            onClick={() =>
              document
                .getElementById("gallery-folders-list")
                .scrollIntoView({ behavior: "smooth" })
            }
          />
        </div>
        {modalShow && (
          <GroupShareModal
            show={modalShow}
            groupDetails={groupDetails}
            onHide={() => setModalShow(false)}
            branding={props?.branding}
            isAdmin={downloadPerms.isAdmin}
            isPrivate={groupDetails?.isPrivate}
          />
        )}
        <UserAgentErrorModal
          show={showErrorModal}
          onHide={() => setShowErrorModal(false)}
          deviceType={deviceType}
          variant={1}
        />
      </div>

      {/*<DefaultGallaryHeading title="John & Joe Wedding" />*/}
    </>
  );
};

export default GallaryHeader;

export function goToDownloadRoute(params, navigate) {
  navigate(`/gallery/download?groupId=${params.get("groupId")}`);
}
